<template>
  <v-card>
    <v-card-title>
      <div v-t="'settings.accommodation.header'"></div>
    </v-card-title>
    <v-card-text>
      <accommodation-dialog></accommodation-dialog>
      <v-data-table
        disable-sort
        hide-default-footer
        :headers="headers"
        :items="accommodations"
      >
        <template v-slot:item.id="{ item }">
          <v-btn
            icon
            @click="selectAccommodation(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        v-t="'settings.accommodation.new'"
        @click="newAccommodation"
      ></v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
  import AccommodationDialog from './AccommodationDialog';
  export default {
    components: {
      AccommodationDialog
    },
    data() {
      return {
        headers: [
          {
            text: this.$t('settings.accommodation.title'),
            value: 'title'
          },
          {
            text: this.$t('settings.accommodation.city'),
            value: 'city'
          },
          {
            text: this.$t('settings.accommodation.zipcode'),
            value: 'zipcode'
          },
          {
            text: this.$t('settings.accommodation.ntak_reg_number'),
            value: 'ntak_reg_number'
          },
          {
            text: this.$t('settings.accommodation.ntak_id'),
            value: 'ntak_id'
          },
          {
            text: '',
            value: 'id'
          }

        ],
      };
    },
    computed: {
      accommodations() {
        return this.$store.state.accommodations.accommodations;
      },
    },
    methods: {
      selectAccommodation(item) {
        this.$store.dispatch('accommodations/selectAccommodation', item);
      },
      newAccommodation() {
        this.$store.dispatch('accommodations/newAccommodation');
      },
    },
    mounted() {
      this.$store.dispatch('accommodations/initialize');
    }
  };
</script>
