import Vue from 'vue';
import Vuetify, { VBtn, VTextField, VSnackbar, VIcon } from 'vuetify/lib';
import hu from 'vuetify/lib/locale/hu';
import en from 'vuetify/lib/locale/en';
import Ripple from 'vuetify/lib/directives/ripple';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
  components: {
    VTextField,
    VSnackbar,
    VBtn,
    VIcon
  }
});

const opts = {
  breakpoint: {
    mobileBreakpoint: 'sm' // This is equivalent to a value of 960
  },
  lang: {
    locales: { hu, en },
    // eslint-disable-next-line no-undef
    current: i18nData.locale,
  },
};

export default new Vuetify(opts);
