<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="model"
    max-width="290px"
    min-width="290px"
    offset-y
    ref="menu"
    transition="scale-transition"
    v-model="open"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        filled
        dense
        v-model="model"
        :hint="hint"
        :error-messages="errorMessages"
        :placeholder="placeholder"
        :readonly="readonly"
        :flat="flat"
        :hide-details="hideDetails"
      >
        <template v-slot:prepend>
          <v-icon
            color="blue"
            v-bind="attrs"
            v-on="on"
          >{{prependIcon}}</v-icon>
        </template>
      </v-text-field>
    </template>
    <slot name="picker"></slot>
  </v-menu>
</template>

<script>
  export default {
    props: ['value', 'label', 'prependIcon', 'errorMessages', 'placeholder', 'readonly', 'hint', 'flat', 'hideDetails'],
    data() {
      return {
        open: false,
      };
    },
    methods: {
      save(m) {
        this.$refs.menu.save(m);
      },
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    }
  };
</script>
