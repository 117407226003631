import { paginatedFetch } from '../../util/paginatedFetch';
import axios from 'axios';

const state = {
  items: [],
  item: null,
  itemErrors: {},
  itemsRequest: false,
  itemRequest: false,
};

const getters = {};

const mutations = {
  selectItem(state, payload) {
    state.item = payload;
  },
  setItems: (state, payload) => {
    state.items = payload;
  },
  setItemRequest: (state, payload) => {
    state.itemRequest = payload;
  },
  setItemErrors: (state, payload) => {
    state.itemErrors = payload;
  },
  setItemsRequest: (state, payload) => {
    state.itemsRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setItems', []);
    commit('setItemsRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.inventory_items.url,
      { query: {} },
      (results) => commit('setItems', state.items.concat(results)),
      () => commit('setItemsRequest', false)
    );
  },
  selectItem: ({ commit }, payload) => {
    commit('selectItem', null);
    if (payload.url) {
      commit('setItemRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectItem', response.data))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setItemRequest', false));
    } else {
      commit('selectItem', payload);
    }
  },
  newItem: ({ commit }) => {
    commit('selectItem', { timestamp: new Date(), quantity: 0 });
  },
  saveItem: ({ commit, dispatch, rootState }, payload) => {
    const user = rootState.authentication;
    const url = payload.url ? payload.url : user.company.resources.inventory_items.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setItemRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setItemErrors', []);
        commit('addMessage', { message: 'inventory.save_success', type: 'success' }, { root: true });
        setTimeout(() => {
          dispatch('initialize');
          return commit('selectItem', response.data);
        }, 1000);
      })
      .catch(response => commit('setItemErrors', response.response.data.errors))
      .finally(() => commit('setItemRequest', false));
  },
  deleteItem: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectItem', null);
        commit('addMessage', { message: 'inventory.delete_success', type: 'success' }, { root: true });
        dispatch('initialize');
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
