<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <dashboard-events
          :events="events"
        >
        </dashboard-events>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <dashboard-reservations
          arrivals
          :date="today"
          :reservations="reservations"
        >
        </dashboard-reservations>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <dashboard-reservations
          :date="today"
          :reservations="reservations"
        >
        </dashboard-reservations>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6">
        <dashboard-reservations
          arrivals
          :date="tomorrow"
          :reservations="reservations"
        >
        </dashboard-reservations>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="6">
        <dashboard-reservations
          :date="tomorrow"
          :reservations="reservations"
        >
        </dashboard-reservations>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import dayjs from 'dayjs';

  import DashboardEvents from './DashboardEvents';
  import DashboardReservations from './DashboardReservations';

  export default {
    components: {
      DashboardEvents,
      DashboardReservations,
    },
    data() {
      const today = dayjs().startOf('day');
      const tomorrow = dayjs().add(1, 'day').endOf('day');
      return {
        today: today.toDate(),
        tomorrow: tomorrow.toDate(),
      };
    },
    computed: {
      events() {
        return this.$store.state.dashboard.events;
      },
      reservations() {
        return this.$store.state.dashboard.reservations;
      },
    },
    mounted() {
      this.$store.dispatch('dashboard/initialize', {
        from: this.today,
        to: this.tomorrow
      });
    },
  };
</script>
