const colors = {
  pending: 'blue-grey',
  confirmed: 'blue',
  checked_in: 'green',
  checked_out: 'orange',
  canceled: 'brown',
  locked: 'deep-purple'
};
const styles = {
  true: 'darken-2 font-weight-bold',
  false: 'font-weight-medium'
};
export default {
  methods: {
    reservationStyle(reservation) {
      const color = this.reservationColor(reservation);

      const style = styles[reservation?.paid];

      return `${color} ${style}`;
    },

    reservationColor(reservation) {
      return colors[reservation?.status?.toLowerCase()];
    },
    reservationTitle(reservation) {
      return reservation.title || reservation.note?.split(/\r\n|\n|\r/)[0] || '-';
    },
  }
};
