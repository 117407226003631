import { paginatedFetch } from '../../util/paginatedFetch';
import axios from 'axios';

const state = {
  accommodations: [],
  accommodation: null,
  accommodationErrors: {},
  accommodationsRequest: false,
  accommodationRequest: false,
};

const getters = {};

const mutations = {
  selectAccommodation(state, payload) {
    state.accommodation = payload;
  },
  setAccommodations: (state, payload) => {
    state.accommodations = payload;
  },
  setAccommodationRequest: (state, payload) => {
    state.accommodationRequest = payload;
  },
  setAccommodationErrors: (state, payload) => {
    state.accommodationErrors = payload;
  },
  setAccommodationsRequest: (state, payload) => {
    state.accommodationsRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setAccommodations', []);
    commit('setAccommodationsRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.ntak.accommodations.url,
      { query: {} },
      (results) => commit('setAccommodations', state.accommodations.concat(results)),
      () => commit('setAccommodationsRequest', false)
    );
  },
  selectAccommodation: ({ commit }, payload) => {
    commit('selectAccommodation', null);
    if (payload.url) {
      commit('setAccommodationRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectAccommodation', response.data))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setAccommodationRequest', false));
    } else {
      commit('selectAccommodation', payload);
    }
  },
  newAccommodation: ({ commit }) => {
    commit('selectAccommodation', { timestamp: new Date() });
  },
  saveAccommodation: ({ commit, dispatch, rootState }, payload) => {
    const user = rootState.authentication;
    const url = payload.url ? payload.url : user.company.resources.ntak.accommodations.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setAccommodationRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setAccommodationErrors', []);
        commit('addMessage', { message: 'settings.accommodation.save_success', type: 'success' }, { root: true });
        setTimeout(() => {
          dispatch('initialize');
          return commit('selectAccommodation', response.data);
        }, 1000);
      })
      .catch(response => commit('setAccommodationErrors', response.response.data.errors))
      .finally(() => commit('setAccommodationRequest', false));
  },
  deleteAccommodation: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectAccommodation', null);
        commit('addMessage', { message: 'settings.accommodation.delete_success', type: 'success' }, { root: true });
        dispatch('initialize');
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
