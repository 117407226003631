<template>
  <v-row>
    <v-col cols="4" lg="2" md="2" sm="2">
      <v-btn
        :title="$d(todayMonth.date, 'month')"
        :to="todayMonth.path"
        block
        outlined
        text
        v-t="'calendar.today'"
      ></v-btn>
    </v-col>
    <v-col cols="4" lg="1" md="1" sm="2">
      <v-btn
        :title="$d(previousMonth.date, 'month')"
        :to="previousMonth.path"
        block
        outlined
        text
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="4" lg="1" md="1" sm="2">
      <v-btn
        :title="$d(nextMonth.date, 'month')"
        :to="nextMonth.path"
        block
        outlined
        text
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="4" lg="1" md="1" sm="2">
      <v-btn
        @click="refresh"
        block
        outlined
        text
        :title="$t('calendar.refresh')"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="4" lg="2" md="2" sm="2">
      <v-menu
        :close-on-content-click="false"
        offset-y
        v-model="monthDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
          >{{ $d(currentMonth.date, 'month') }}
          </v-btn>
        </template>
        <v-sheet
          elevation="4"
        >
          <v-date-picker
            :locale="locale"
            v-model="currentDate"
            @change="handleDateChange"
            type="month"
          ></v-date-picker>
        </v-sheet>
      </v-menu>
    </v-col>
    <v-col cols="6" lg="5" md="5" sm="3">
      <template v-if="type === 'calendar'">
        <span
          class="v-btn v-btn--plain v-size--default"
        >{{ calendar.title }}</span>
        <v-btn text @click="handlePrintClick" :title="$t('calendar.print')" class="float-lg-right float-md-right">
          <v-icon>mdi-printer-outline</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          text
          :to="{ name: 'calendar', params: { id: room.calendar.id, year, month } }"
        >{{ room.calendar.title }}</v-btn>
        /
        <span
          class="v-btn v-btn--plain v-size--default"
        >{{ room.title }}</span>
      </template>
    </v-col>
  </v-row>
</template>

<script>
  import { router } from '../../../routes/router';

  const formatPath = (type, date, id) => `/${type}s/${id}/${date.getFullYear()}/${date.getMonth() + 1}`;

  const monthData = (type, month, id) => {
    return {
      date: month,
      path: formatPath(type, month, id)
    };
  };

  const months = (type, yearString, monthString, id) => {
    const year = parseInt(yearString);
    const month = parseInt(monthString);

    return {
      previousMonth: monthData(type, new Date(year, month - 2), id),
      nextMonth: monthData(type, new Date(year, month), id),
      currentMonth: monthData(type, new Date(year, month - 1), id),
      todayMonth: monthData(type, new Date(), id),
      id,
    };
  };
  export default {
    props: {
      year: {
        type: Number
      },
      month: {
        type: Number
      },
      type: {
        type: String
      }
    },

    data() {
      const { year, month, id } = this.$route.params;
      return {
        currentDate: `${year}-${month}`,
        monthDialog: false,
        ...months(this.type, this.year, this.month, id)
      };
    },
    computed: {
      locale() {
        return this.$store.state.authentication.company.locale;
      },
      calendar() {
        return this.$store.getters['authentication/getCalendarById'](parseInt(this.id));
      },
      room() {
        return this.$store.getters['authentication/getRoomById'](parseInt(this.id));
      },
    },
    methods: {
      handlePrintClick() {
        window.open(`${this.calendar.print_url}?year=${this.year}&month=${this.month}`, '_blank');
      },
      handleDateChange(event) {
        const [year, month] = event.split('-');
        const path = `/${this.type}s/${this.id}/${year}/${month}`;
        this.monthDialog = false;

        if (router.currentRoute.path === path) {
          return;
        }
        router.push(path);
      },
      refresh() {
        if (this.type === 'calendar') {
          return this.$store.dispatch('calendar/initialize', this.$route.params);
        } else {
          return this.$store.dispatch('room/initialize', this.$route.params);
        }
      }
    },
    watch: {
      '$route'(to) {
        const { year, month, id } = to.params;
        const { currentMonth, previousMonth, nextMonth, todayMonth } = months(this.type, year, month, id);

        this.previousMonth = previousMonth;
        this.nextMonth = nextMonth;
        this.currentMonth = currentMonth;
        this.todayMonth = todayMonth;
        this.id = id;
      },
    },
  };
</script>
