<template>
  <picker-container
    :prepend-icon="prependIcon"
    v-model="time"
    :label="label"
    ref="picker"
    hint="HH:mm"
  >
    <template v-slot:picker>
      <v-time-picker
        @click:minute="$refs.picker.save(time)"
        full-width
        v-model="time"
        :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
      ></v-time-picker>
    </template>
  </picker-container>
</template>

<script>
  import PickerContainer from './PickerContainer';
  export default {
    components: {
      PickerContainer,
    },
    props: ['value', 'label', 'prependIcon'],
    computed: {
      time: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit('input', newValue);
        }
      }
    },
  };
</script>
