<template>
  <v-expansion-panels
    multiple
    v-model="panel"
  >
    <search-results
      type="contacts"
      :items="$store.state.search.people"
      :request="$store.state.search.peopleRequest"
    ></search-results>
    <search-results
      type="reservations"
      :items="$store.state.search.reservations"
      :request="$store.state.search.reservationsRequest"
    ></search-results>
    <search-results
      type="events"
      :items="$store.state.search.events"
      :request="$store.state.search.eventsRequest"
    ></search-results>
  </v-expansion-panels>
</template>

<script>
  import SearchResults from './SearchResults';

  export default {
    components: {
      SearchResults,
    },
    data() {
      return {
        panel: this.from === 'calendar' ? [1] : [0, 1, 2],
      };
    },
    computed: {
      query() {
        return this.$route.query.query;
      },
      from() {
        return this.$route.query.from;
      },
    },
    methods: {
      search() {
        if (!this.query) {
          return;
        }
        this.$store.dispatch('search/initialize', { search: this.query });
      },
    },
    watch: {
      query() {
        this.search();
      },
      from() {
        this.panel = this.from === 'calendar' ? [1] : [0, 1, 2];
      },
    },
    mounted() {
      this.panel = this.$route.query.from === 'calendar' ? [1] : [0, 1, 2];
      this.search();
    }
  };
</script>
