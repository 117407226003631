export default {
  methods: {
    translatedListFor(prefix, items, postfix) {
      return items.map(item => ({ text: this.$t(this.join(prefix, String(item), postfix)), value: item }));
    },
    join(...args) {
      return args.filter(x => x).join('.');
    },
  }
};
