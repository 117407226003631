export default {
  computed: {
    softwareTitle() {
      if (window.environment === 'production') {
        return this.$t('software.title');
      } else {
        return `${this.$t('software.title')} - ${this.$t('software.test_environment')}`;
      }
    },
    softwareEnvironment() {
      return window.environment;
    }
  }
};
