<template>
  <picker-container
    :prepend-icon="prependIcon"
    v-model="dateTime"
    :label="label"
    ref="picker"
    :hint="`${dateFormat} ${timeFormat}`"
    :error-messages="errorMessages"
    :flat="flat"
    :hide-details="hideDetails"
  >
    <template v-slot:picker>
      <v-card>
        <v-card-text class="px-0 py-0">
          <v-tabs fixed-tabs v-model="activeTab">
            <v-tab key="calendar">
              <slot name="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </slot>
            </v-tab>
            <v-tab key="timer" :disabled="!date">
              <slot name="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                v-model="date"
                :locale="locale"
                @input="showTimePicker"
              ></v-date-picker>
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                ref="timer"
                class="v-time-picker-custom"
                v-model="time"
                :allowed-minutes="[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]"
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions" :parent="this">
            <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
            <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </template>
  </picker-container>
</template>

<script>
  import dayjs from 'dayjs';

  import PickerContainer from './PickerContainer';

  const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
  const DEFAULT_TIME_FORMAT = 'HH:mm';
  const DEFAULT_DIALOG_WIDTH = 340;
  const DEFAULT_CLEAR_TEXT = 'CLEAR';
  const DEFAULT_OK_TEXT = 'OK';

  export default {
    components: {
      PickerContainer,
    },
    props: {
      value: {
        type: [Date, String],
        default: null
      },
      disabled: {
        type: Boolean
      },
      label: {
        type: String,
        default: ''
      },
      dialogWidth: {
        type: Number,
        default: DEFAULT_DIALOG_WIDTH
      },
      dateFormat: {
        type: String,
        default: DEFAULT_DATE_FORMAT
      },
      timeFormat: {
        type: String,
        default: DEFAULT_TIME_FORMAT
      },
      clearText: {
        type: String,
        default: DEFAULT_CLEAR_TEXT
      },
      okText: {
        type: String,
        default: DEFAULT_OK_TEXT
      },
      prependIcon: {
        type: String,
        default: 'mdi-calendar-clock'
      },
      errorMessages: {
        type: [String, Array]
      },
      flat: {
        type: Boolean
      },
      hideDetails: {
        type: Boolean
      },
    },
    data() {
      const datetime = dayjs(this.value);
      return {
        activeTab: 0,
        focus: false,
        currentDateTime: datetime,
        dateTime: datetime.format(this.dateFormat + ' ' + this.timeFormat),
        date: datetime.format(this.dateFormat),
        time: datetime.format(this.timeFormat),
      };
    },
    computed: {
      locale() {
        return this.$store.state.authentication.company.locale;
      },
    },
    watch: {
      focus() {
        this.setCurrentDateTime();
      },
      dateTime() {
        this.setCurrentDateTime();
      },
      currentDateTime() {
        if (this.currentDateTime.isValid()) {
          this.$emit('input', this.currentDateTime.toDate());
        }
      },
      value() {
        if (!this.value) {
          return;
        }
        const datetime = dayjs(this.value);
        if (this.currentDateTime.isSame(datetime, 'minute')) {
          return;
        }
        this.activeTab = 0;
        this.focus = false;
        this.currentDateTime = datetime;
        this.dateTime = datetime.format(this.dateFormat + ' ' + this.timeFormat);
        this.date = datetime.format(this.dateFormat);
        this.time = datetime.format(this.timeFormat);
      },
    },
    methods: {
      setCurrentDateTime() {
        if (this.focus) {
          return;
        }
        const newDateTime = dayjs(this.dateTime);
        if (!this.currentDateTime.isSame(newDateTime, 'minute')) {
          this.currentDateTime = newDateTime;
        }
      },
      parseValue(date, time) {
        if (date && time) {
          this.currentDateTime = dayjs(date + ' ' + time);
          this.dateTime = this.currentDateTime.format(this.dateFormat + ' ' + this.timeFormat);
        }
      },
      okHandler() {
        this.parseValue(this.date, this.time);
        this.resetPicker();
        if (this.currentDateTime.isValid()) {
          this.$emit('input', this.currentDateTime.toDate());
          this.$refs.picker.save(this.dateTime);
        }
      },
      clearHandler() {
        this.resetPicker();
      },
      resetPicker() {
        this.activeTab = 0;
        if (this.$refs.timer) {
          this.$refs.timer.selectingHour = true;
        }
      },
      showTimePicker() {
        this.activeTab = 1;
      }
    },
  };
</script>
