<template>
  <tr class="header">
    <td class="empty room-title"></td>
    <td class="empty room-icon" ref="titleCell"></td>
    <td :key="day.title" class="subtitle-2" :class="{top: withRef, bottom: !withRef, [day.day]: true}" ref="dayCell" v-for="day in days">
      {{day.title}}
      <br>
      {{day.number}}
    </td>
    <td class="empty room-icon" ref="lastCell"></td>
    <td class="empty room-title"></td>
  </tr>
</template>

<script>
  export default {
    props: {
      days: {
        type: Array
      },
      withRef: {
        type: Boolean
      }
    }
  };
</script>
