<template>
  <v-combobox
    multiple
    v-model="items"
    :label="$t(`contact.${type}`)"
    chips
    :prepend-icon="icon"
    deletable-chips
    :search-input.sync="search"
    :error-messages="errorMessages"
  >
    <template v-slot:selection="{ item }">
      <v-chip
        label
        close
        @click:close="close(item)"
      >
        <v-btn
          :href="`${type === 'email' ? 'mailto:' : 'tel:'}${item}`"
          :target="type === 'email' ? '_blank' : ''"
          icon
        >
          <v-icon color="blue">{{ icon }}</v-icon>
        </v-btn>
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    props: {
      value: {
        type: Array
      },
      type: {
        type: String,
      },
      errorMessages: {
        type: Array,
      }
    },
    data: () => ({
      search: null,
    }),
    computed: {
      items: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      icon() {
        return this.type === 'email' ? 'mdi-email' : 'mdi-phone';
      },
    },
    methods: {
      close(item) {
        this.items = this.items.filter(i => i !== item);
        this.$nextTick(() => {
          this.search = item;
        });
      },
    },
  };
</script>
