<template>
  <v-card>
    <v-card-title>
      <div v-t="'settings.messages.header'"></div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        disable-sort
        :hide-default-footer="messages.length < 11"
        :headers="headers"
        :items="messages"
      >
        <template v-slot:item.created_at="{ item }">
          {{ dayjs(item.created_at).format('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ dayjs(item.updated_at).format('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ $t(`settings.messages.statuses.${item.status}`)}}
        </template>
        <template v-slot:item.type="{ item }">
          {{ $t(`settings.messages.types.${item.type}`)}}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import dayjs from 'dayjs';

  export default {
    components: {
    },
    data() {
      return {
        headers: [
          {
            text: this.$t('settings.messages.type'),
            value: 'type',
          },
          {
            text: this.$t('settings.messages.status'),
            value: 'status',
          },
          {
            text: this.$t('settings.messages.accommodation'),
            value: 'accommodation.title',
          },
          {
            text: this.$t('settings.messages.created_at'),
            value: 'created_at',
          },
          {
            text: this.$t('settings.messages.updated_at'),
            value: 'updated_at',
          }
        ]
      };
    },
    computed: {
      messages() {
        return this.$store.state.messages.messages;
      },
    },
    methods: {
      dayjs
    },
    mounted() {
      dayjs.locale(this.$i18n.locale);
      this.$store.dispatch('messages/initialize');
    }
  };
</script>
