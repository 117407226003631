<template>
  <dialog-container
    v-model="open"
    :close="reallyClose"
    :item="calendar"
    :title="title"
    :dirty="dirty"
    type="calendar"
    ref="dialog"
    klass="teal"
  >
    <template v-slot:body>
      <v-container fluid>
        <v-text-field
          :error-messages="errors.title"
          :label="$t('settings.calendars.title')"
          dense
          filled
          prepend-icon="mdi-subtitles"
          v-model="title"
        ></v-text-field>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-t="'settings.calendars.save'"
        :disabled="!dirty"
      ></v-btn>

      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="calendar?.url"
        v-t="'settings.calendars.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'settings.calendars.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../../shared/DialogContainer';
  export default {
    components: {
      DialogContainer
    },
    data() {
      return {
        open: false,
        title: '',
      };
    },
    computed: {
      dirty() {
        return this.title !== this.calendar?.title;
      },
      calendar() {
        return this.$store.state.settings.selectedCalendar;
      },
      errors() {
        return this.$store.state.settings.calendarErrors;
      },
    },
    methods: {
      save() {
        this.$store.dispatch('settings/saveCalendar', {
          url: this.calendar.url,
          data: {
            title: this.title,
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('settings.calendars.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('settings/deleteCalendar', this.calendar);
            }
          });
      },
      reallyClose() {
        this.open = false;
        this.$store.commit('settings/setCalendarErrors', []);
      },
    },
    watch: {
      calendar() {
        if (!this.calendar) {
          this.open = false;
          return;
        }

        this.open = true;
        this.title = this.calendar.title;
      },
    }
  };
</script>
