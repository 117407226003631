<template>
  <v-row>
    <v-col cols="10" lg="10" md="10" sm="10">
      <v-tooltip bottom v-if="editing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop.prevent="click"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="grey lighten-1">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span v-t="'settings.calendars.edit'"></span>
      </v-tooltip>
      <v-tooltip bottom v-if="editing">
        <template v-slot:activator="{ on, attrs }">
          <span v-on="on" v-bind="attrs">{{calendar.title}}</span>
        </template>
        <span v-t="'settings.calendars.open'"></span>
      </v-tooltip>
      <span v-else>{{calendar.title}}</span>
    </v-col>
    <v-col class="text-right" cols="2" lg="2" md="2" sm="2">
      <v-tooltip bottom v-if="editing">
        <template v-slot:activator="{ on, attrs}">
          <v-btn
            @click.stop="addRoom"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus-thick</v-icon>
          </v-btn>
        </template>
        <span v-t="'settings.rooms.new'"></span>
      </v-tooltip>
      <v-btn :disabled="first" @click.stop="up" icon v-if="!editing">
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
      <v-btn :disabled="last" @click.stop="down" icon v-if="!editing">
        <v-icon>mdi-arrow-down-bold</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      value: {
        type: Object
      },
      calendars: {
        type: Array
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        calendar: this.value,
      };
    },
    computed: {
      readonly() {
        if (!this.calendar) {
          return;
        }
        return this.calendar.url && (this.calendar.auth || []).indexOf('update') === -1;
      },
      index() {
        return this.calendars.indexOf(this.calendar);
      },
      first() {
        return this.index < 1;
      },
      last() {
        if (this.index === -1) {
          return true;
        }
        return this.index === this.calendars.length - 1;
      },
    },
    methods: {
      click() {
        this.$store.commit('settings/selectCalendar', { timestamp: new Date(), ...this.calendar });
      },
      addRoom() {
        this.$store.commit('settings/selectRoom', { timestamp: new Date(), calendar: this.calendar });
      },
      up() {
        if (this.first) {
          return;
        }

        const otherCalendar = this.calendars[this.index - 1];
        // eslint-disable-next-line vue/no-mutating-props
        this.calendars.splice(this.index - 1, 2, this.calendar, otherCalendar);
      },
      down() {
        if (this.last) {
          return;
        }

        const otherCalendar = this.calendars[this.index + 1];
        // eslint-disable-next-line vue/no-mutating-props
        this.calendars.splice(this.index, 2, otherCalendar, this.calendar);
      },
    },
  };
</script>
