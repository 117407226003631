// eslint-disable-next-line no-extend-native
Date.prototype.isSameDay = function (other) {
  return (other.getFullYear() === this.getFullYear() &&
    other.getMonth() === this.getMonth() &&
    other.getDate() === this.getDate());
};

// eslint-disable-next-line no-extend-native
Date.firstOf = function (year, month) {
  return new Date(parseInt(year), parseInt(month) - 1, 1);
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default Date;
