<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-text-field
          :error-messages="error.name"
          :label="$t('account.name')"
          name="name"
          prepend-icon="mdi-account"
          type="text"
          v-model="name"
        ></v-text-field>

        <v-text-field
          :error-messages="error['company[users[0].email]']"
          :label="$t('account.email')"
          name="email"
          prepend-icon="mdi-account"
          type="text"
          v-model="email"
        ></v-text-field>

        <v-text-field
          :error-messages="error.title"
          :label="$t('account.title')"
          name="title"
          prepend-icon="mdi-account"
          type="text"
          v-model="title"
        ></v-text-field>

        <v-text-field
          :error-messages="error.password"
          :label="$t('account.password')"
          id="password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          v-model="password"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="handleRegister" color="primary" v-t="'account.submit'"></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        name: '',
        title: '',
      };
    },
    computed: {
      error() {
        return this.$store.state.authentication.registrationErrors;
      },
    },
    methods: {
      handleRegister() {
        this.$recaptcha('register').then(token => {
          this.$store.dispatch('authentication/register', {
            email: this.email,
            password: this.password,
            name: this.name,
            title: this.title,
            recaptcha_token: token,
          });
        });
      },
    },
  };
</script>
