import Vue from 'vue';
import vuetify from '../plugins/vuetify'; // path to vuetify export
import VueRouter from 'vue-router';
import VuetifyConfirm from 'vuetify-confirm';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import CountryFlag from 'vue-country-flag';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import App from '../components/layout/App.vue';
import { store } from '../store/store';
import { router } from '../routes/router';
import axios from 'axios';

import i18n from '../plugins/i18n';
// eslint-disable-next-line no-unused-vars
import date from '../plugins/date';
// eslint-disable-next-line no-unused-vars
import rollbar from '../plugins/rollbar';
// eslint-disable-next-line no-unused-vars
import draggable from '../plugins/draggable';
// eslint-disable-next-line no-unused-vars
import * as mdi from '@mdi/font/css/materialdesignicons.min.css';
// eslint-disable-next-line no-unused-vars
import * as font from 'fontsource-roboto';

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails');

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueRouter);
  Vue.use(VuetifyConfirm, {
    vuetify,
    // eslint-disable-next-line no-undef
    buttonTrueText: i18nData.translations.yes_text,
    // eslint-disable-next-line no-undef
    buttonFalseText: i18nData.translations.no_text,
  });
  Vue.component('country-flag', CountryFlag);
  Vue.use(VuetifyToast, {
    x: 'center', // default
    y: 'top', // default
    color: 'info', // default
    icon: 'mdi-information',
    iconColor: '', // default
    classes: [
      'body-2'
    ],
    timeout: 3000, // default
    dismissable: true, // default
    multiLine: false, // default
    vertical: false, // default
    queueable: false, // default
    showClose: false, // default
    closeText: '', // default
    closeIcon: 'close', // default
    closeColor: '', // default
    slot: [], // default
    shorts: {
      custom: {
        color: 'purple'
      }
    },
    property: '$toast' // default
  });
  Vue.use(VueReCaptcha, {
    // eslint-disable-next-line no-undef
    siteKey: recaptcha_site_key
  });

  const app = new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)

  }).$mount();
  document.body.appendChild(app.$el);
});