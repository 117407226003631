<template>
  <dialog-container
    :close="reallyClose"
    v-model="open"
    :item="event"
    :title="title"
    :dirty="dirty"
    type="event"
    ref="dialog"
    klass="blue"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col
            cols="12"
          >
            <v-text-field
              :error-messages="eventErrors.title"
              :label="$t('event.title')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="title"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <datetime-picker
              :disabled="readonly"
              :label="$t('event.starts_at')"
              :error-messages="eventErrors.starts_at"
              prepend-icon="mdi-calendar-clock"
              v-model="startsAt"
            ></datetime-picker>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-textarea
              :error-messages="eventErrors.note"
              :label="$t('event.note')"
              :readonly="readonly"
              auto-grow
              filled
              prepend-icon="mdi-text"
              v-model="note"
            ></v-textarea>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <guests-autocomplete
              :label="$t('event.guests')"
              :existing="!!event?.url"
              type="Event"
              :readonly="readonly"
              v-model="guests"
              :errors="eventErrors"
            ></guests-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="jump"
        text
        v-if="jumpable"
        v-t="'reservation.jump'"
      ></v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'event.save'"
        :disabled="eventRequest || !dirty"
      ></v-btn>
      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="event?.url && !readonly"
        v-t="'event.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'event.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import GuestsAutocomplete from '../shared/GuestsAutocomplete';
  import DialogContainer from '../shared/DialogContainer';
  import DatetimePicker from '../shared/pickers/DatetimePicker';
  import { router } from '../../routes/router';
  import { compareGuests, compareDates } from '../../util/comparators';

  export default {
    components: {
      DialogContainer,
      DatetimePicker,
      GuestsAutocomplete,
    },
    data() {
      return {
        open: false,
        note: '',
        startsAt: new Date(),
        title: '',
        guests: [],
      };
    },
    computed: {
      dirty() {
        const event = this.event || {};
        return !(this.title === event.title &&
          compareDates(this.startsAt, event?.startsAt) &&
          this.note === event.note &&
          compareGuests(this.guests, event.guests));
      },
      event() {
        return this.$store.state.calendar.selectedEvent;
      },
      eventErrors() {
        return this.$store.state.calendar.eventErrors;
      },
      eventRequest() {
        return this.$store.state.calendar.eventRequest;
      },
      readonly() {
        if (!this.event) {
          return;
        }
        return this.event.url && (this.event.auth || []).indexOf('update') === -1;
      },
      jumpable() {
        return (this.event || {}).id;
      },
    },
    watch: {
      event() {
        if (!this.event) {
          this.open = false;
          return;
        }
        this.open = true;
        this.note = this.event.note;
        this.startsAt = this.event.startsAt;
        this.title = this.event.title;
        this.guests = (this.event.guests || []).slice(0);
      }
    },
    methods: {
      reallyClose() {
        this.$store.commit('calendar/setEventErrors', []);
        this.open = false;
      },
      save() {
        this.$store.dispatch('calendar/saveEvent', {
          url: this.event.url,
          data: {
            starts_at: this.startsAt,
            title: this.title,
            note: this.note,
            guests_attributes: this.guests,
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('event.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('calendar/deleteEvent', {
                url: this.event.url,
              }).then(() => {
                this.open = false;
              });
            }
          });
      },
      jump() {
        let { id } = this.$store.state.authentication.company.calendars[0];
        const year = this.startsAt.getFullYear().toString();
        const month = (this.startsAt.getMonth() + 1).toString();
        if (router.currentRoute.name === 'calendar') {
          id = router.currentRoute.params.id;
        }
        router.push({ name: 'calendar', params: { id, year, month } }).catch(() => {});
        this.$refs.dialog.dialogClose();
      },
    },
  };
</script>
