<template>
  <v-card>
    <v-card-title>
      <div v-t="'settings.certificates.header'"></div>
    </v-card-title>
    <v-card-text>
      <certificate-table
        :certificates="certificates"
      ></certificate-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import dayjs from 'dayjs';
  import CertificateTable from './CertificateTable';

  export default {
    components: {
      CertificateTable,
    },
    computed: {
      certificates() {
        return this.$store.state.certificates.certificates;
      },
    },
    mounted() {
      dayjs.locale(this.$i18n.locale);
      this.$store.dispatch('certificates/initialize');
    }
  };
</script>
