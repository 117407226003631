<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h6" v-t="'settings.ntak.header'"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div v-t="'settings.ntak.company_details'"></div>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" v-for="field in fields" :key="field">
                  <strong v-t="`settings.company.${field}`"></strong>: {{ company[field] }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <accommodations></accommodations>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <certificates></certificates>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <messages></messages>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <rooms-without-accommodation></rooms-without-accommodation>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        &nbsp;
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Accommodations from './Accommodations';
  import Certificates from './Certificates';
  import Messages from './Messages';
  import RoomsWithoutAccommodation from './RoomsWithoutAccommodation';

  export default {
    components: {
      Accommodations,
      Certificates,
      Messages,
      RoomsWithoutAccommodation,
    },
    data() {
      return {
        fields: ['title', 'tax_number', 'zipcode', 'city'],
      };
    },
    computed: {
      company() {
        return this.$store.state.authentication.company;
      },
    },
  };
</script>
