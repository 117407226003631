import { reservationFactory } from './reservation';
import { eventFactory } from './event';

const personFactory = ({ reservations, events, ...rest }) => {
  return ({
    ...rest,
    reservations: reservations?.map(reservationFactory) || [],
    events: events?.map(eventFactory) || [],
    itemType: 'person',
  });
};

export {
  personFactory
};
