<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h6" v-t="'inventory.header'"></div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          outlined
          text
          @click="newItem"
        >
          {{ $t('inventory.new') }}
        </v-btn>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <inventory-item-dialog></inventory-item-dialog>
        <v-data-table
          :disable-pagination="true"
          disable-sort
          :headers="headers"
          :hide-default-footer="true"
          :items="items"
          :search="search"
          @click:row="selectItem"
          calculate-widths
          class="fill-height"
          fixed-header
          sort-by="name"
          :custom-filter="advancedSearch"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              :label="$t('inventory.search')"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:body.append>
            <tr>
              <td></td>
              <td class="font-weight-bold" v-t="'inventory.total'"></td>
              <td class="text-xs-right font-weight-bold">{{ totals.price }}</td>
              <td class="text-xs-right font-weight-bold">{{ totals.vendorPrice }}</td>
              <td class="text-xs-right font-weight-bold">{{ totals.quantity }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import InventoryItemDialog from './InventoryItemDialog.vue';

  export default {
    components: {
      InventoryItemDialog
    },
    data() {
      return {
        headers: [
          {
            text: this.$t('inventory.title'),
            value: 'title'
          },
          {
            text: this.$t('inventory.partno'),
            value: 'partno'
          },
          {
            text: this.$t('inventory.price'),
            value: 'price'
          },
          {
            text: this.$t('inventory.vendor_price'),
            value: 'vendor_price'
          },
          {
            text: this.$t('inventory.quantity'),
            value: 'quantity'
          }
        ],
        dialog: false,
        search: ''
      };
    },
    computed: {
      items() {
        return this.$store.state.inventory.items;
      },
      totals() {
        return {
          price: this.items.map(i => i.price * i.quantity).reduce((m, i) => m + i, 0),
          vendorPrice: this.items.map(i => i.vendor_price * i.quantity).reduce((m, i) => m + i, 0),
          quantity: this.items.map(i => i.quantity).reduce((m, i) => m + i, 0),
        };
      },
    },
    methods: {
      selectItem(item) {
        this.$store.dispatch('inventory/selectItem', item);
      },
      newItem() {
        this.$store.dispatch('inventory/newItem');
      },
      advancedSearch(value, search, item) {
        return value !== null &&
          search !== null &&
          typeof value === 'string' &&
          value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
            .indexOf(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) > -1;
      },
    },
    mounted() {
      this.$store.dispatch('inventory/initialize');
    }
  };
</script>
