<template>
  <v-container
    class="guests-container"
    fluid
  >
    <v-icon :color="iconColor">mdi-set-center</v-icon>
    <div class="guests-autocomplete rounded-t" :class="{errors: hasErrors}">
      <v-autocomplete
        :filter="()=>true"
        :items="peopleSearchItems"
        :label="label"
        :placeholder="label"
        :readonly="readonly"
        :search-input.sync="peopleSearch"
        class="ma-0"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="id"
        multiple
        return-object
        :error-messages="errors.people"
        v-model="guests"
      >
        <template v-slot:label>
          <span :class="{'error--text': hasErrors}">{{ label }}</span>
        </template>
        <template v-slot:selection></template>
        <template v-slot:item="{ item }">
          <person-list-item
            :item="item"
            @click="peopleSearch = null"
          ></person-list-item>
        </template>
        <template v-slot:append-outer>
          <v-btn
            @click="addNewGuest"
            v-t="'contact.new'"
          ></v-btn>
        </template>
      </v-autocomplete>
      <v-list
        class="guest-list pa-0"
        :class="{big: $vuetify.breakpoint.lgAndUp}"
      >
        <v-list-item
          :key="item.person_id"
          v-for="item in filteredGuests"
          three-line
          class="pa-0"
        >
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              <div
                :class="{'guest-name': isReservation}"
                @click="selectPerson(item)"
                style="cursor: pointer"
              >
                <span>{{item.name}}</span>
                <br>
                <span class="grey--text" v-if="item.address">{{item.address}}</span>
                <template
                  v-if="personErrors[item.person_id]"
                >
                  <br>
                  <guest-error
                    :errors="personErrors[item.person_id]"
                  ></guest-error>
                </template>
              </div>
              <translated-select
                v-if="isReservation"
                :items="$store.state.data.touristTaxes"
                :label="$t('contact.tourist_tax')"
                filled
                prefix="contact.tourist_taxes"
                :hide-details="true"
                v-model="item.tourist_tax"
              ></translated-select>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="pa-0 ma-auto d-flex align-center">
            <v-btn @click.stop="remove(item)" icon>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import TranslatedSelect from '../shared/TranslatedSelect';
  import { guestDataFor } from '../../util/guest';
  import GuestError from './GuestError';
  import PersonListItem from './PersonListItem';

  export default {
    components: {
      GuestError,
      PersonListItem,
      TranslatedSelect,
    },
    props: ['value', 'label', 'existing', 'type', 'readonly', 'errors'],
    data() {
      return {
        guests: this.value,
        peopleSearchItems: this.value,
        peopleSearch: '',
      };
    },
    computed: {
      filteredGuests() {
        return this.guests?.filter(guest => guest._destroy !== 1);
      },
      isReservation() {
        return this.type === 'Reservation';
      },
      user() {
        return this.$store.state.authentication;
      },
      personErrors() {
        const keys = Object.keys(this.errors).filter(key => key.startsWith('people['));
        const errors = {};
        keys.forEach(key => {
          // eslint-disable-next-line no-unused-vars
          const [_, id, attribute] = key.match(/people\[(\d+)]\.([^.]*)/);
          errors[id] = errors[id] || {};
          errors[id][attribute] = [...errors[id][attribute] || [], ...this.errors[key]];
        });

        return errors;
      },
      hasErrors() {
        return Object.keys(this.personErrors).length > 0;
      },
      iconColor() {
        return this.hasErrors || !!this.errors.people ? 'red' : '';
      },
    },
    methods: {
      addNewGuest() {
        this.$store.dispatch('contacts/selectPerson', {
          name: this.peopleSearch,
          ['addTo' + this.type]: true,
        });
      },
      selectPerson(item) {
        if (!this.existing) {
          return;
        }
        this.$store.dispatch('contacts/selectPerson', item);
      },
      remove(item) {
        if (this.readonly) {
          return;
        }
        const index = this.guests.indexOf(item);
        if (index >= 0) {
          this.guests.splice(index, 1);
        }
        this.guests.push({ _destroy: 1, id: item.id });
      },
      search(value) {
        const data = {
          query: {
            search: value,
            limit: 50,
            exclude_ids: this.guests.map(guest => guest.person_id)
          }
        };

        return axios
          .post(this.user.resources.queries.people.url, data)
          .then(response => {
            const results = response.data.results;
            this.peopleSearchItems = [...this.guests, ...results.map(person => guestDataFor(person))];
          });
      },
    },
    watch: {
      value() {
        this.guests = this.value;
        this.peopleSearchItems = this.value;
        this.peopleSearch = null;
      },
      peopleSearch(value) {
        if (value === null) {
          return;
        }
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => this.search(value), 500);
      },
      guests() {
        if (JSON.stringify(this.guests) !== JSON.stringify(this.value)) {
          this.$emit('input', this.guests);
        }
      }
    },
  };
</script>

<style lang="scss">
@import 'vuetify/src/styles/styles.sass';

.guests-container {
  display: flex;
  align-items: flex-start;
  padding: 0;

  > .v-icon {
    margin-right: 9px;
    margin-top: 17px;
  }

  .guest-list {
    &.big {
      .v-list-item__title {
        display: flex;
        .guest-name {
          width: 75%;
          overflow-x: hidden;
        }
        .v-select {
          width: 25%;
        }
      }
    }
  }
}

.guests-autocomplete {
  background: rgba(0, 0, 0, .06);
  display: inline-grid;
  border-bottom: 1px solid black;
  padding: 12px;
  width: 100%;

  &.errors {
    border-bottom: 1px solid map-deep-get($red, 'base');
  }

  label {
    transform: translateY(-18px) scale(.75);
  }

  .v-list-item {
    width: 100%;
  }

  .v-list {
    background: rgba(0, 0, 0, 0);
    min-height: 11rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .v-select__slot {
    background: rgba(0, 0, 0, 0.0);
    max-height: 400px;

    .v-select__selections {

      input {
        min-height: 20px;
        display: block;
        width: 100%;
        height: 32px;
      }
    }
  }
}
</style>
