<template>
  <v-select
          :prepend-icon="prependIcon"
          :items="translatedItems"
          :label="label"
          :readonly="readonly"
          filled
          dense
          v-model="item"
          :error-messages="errorMessages"
          :hide-details="!!hideDetails"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" :three-line="hasDescription">
        <v-list-item-content>
          <v-list-item-title>{{ getTitle(item.value) }}</v-list-item-title>
          <v-list-item-subtitle
                  v-if="hasDescription"
          >{{ getDescription(item.value) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
  import translatedList from '../../mixins/translated_list';

  export default {
    mixins: [translatedList],
    props: ['label', 'readonly', 'value', 'prefix', 'postfix', 'items', 'prependIcon', 'hasDescription', 'errorMessages', 'hideDetails'],
    data() {
      return {
        item: this.value,
      };
    },
    computed: {
      translatedItems() {
        return this.translatedListFor(this.prefix, this.items, this.postfix);
      }
    },
    methods: {
      getDescription(item) {
        return this.getTitle(String(item), 'description');
      },
      getTitle(item, postfix) {
        return this.$t(this.join(this.prefix, String(item), postfix || this.postfix));
      },
    },
    watch: {
      item() {
        this.$emit('input', this.item);
      },
      value() {
        this.item = this.value;
      },
    }
  };
</script>
