<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-text-field
          :error-messages="error"
          :label="$t('account.email')"
          name="email"
          prepend-icon="mdi-account"
          type="text"
          v-model="email"
          @keyup.enter="handleLogin"
        ></v-text-field>

        <v-text-field
          :error-messages="error"
          :label="$t('account.password')"
          id="password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          v-model="password"
          @keyup.enter="handleLogin"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="handleLogin" color="primary" v-t="'account.submit'"></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        password: ''
      };
    },
    computed: {
      error() {
        return this.$store.state.authentication.error;
      }
    },
    methods: {
      handleLogin() {
        this.$store.dispatch('authentication/login', { email: this.email, password: this.password });
      }
    },
  };
</script>
