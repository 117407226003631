<template>
  <vue-card-stack
    ref="stack"
    :cards="cards"
    :stack-width="'100%'"
    :card-width="400"
  >
    <template v-slot:card="{ card }">
      <v-card
        height="400"
      >
        <v-img
          :src="card.src"
          max-height="400"
          max-width="600"
        ></v-img>
        <v-card-title>{{ $t(card.title) }}</v-card-title>
      </v-card>
    </template>
  </vue-card-stack>
</template>

<script>
  import VueCardStack from 'vue-card-stack';
  import welcome1 from '~/../assets/images/welcome-1.png';
  import welcome2 from '~/../assets/images/welcome-2.png';
  import welcome3 from '~/../assets/images/welcome-3.png';
  import welcome4 from '~/../assets/images/welcome-4.png';

  export default {
    components: {
      VueCardStack,
    },
    data() {
      return {
        cards: [
          { src: welcome1, title: 'presentation.dashboard' },
          { src: welcome2, title: 'presentation.calendar' },
          { src: welcome3, title: 'presentation.room' },
          { src: welcome4, title: 'presentation.contacts' }
        ],
      };
    },
    mounted() {
      setInterval(() => this.$refs.stack.onNext(), 15000);
    }
  };
</script>
