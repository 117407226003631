<template>
  <v-select
    :label="$t('statistics.year')"
    :items="years"
    v-model="year"
  ></v-select>
</template>

<script>
  import dayjs from 'dayjs';

  const firstYear = 2010;

  export default {
    props: {
      value: {
        type: Number,
      },
    },
    computed: {
      year: {
        get() { return this.value; },
        set(value) { this.$emit('input', value); }
      },
      years() {
        const years = [];
        for (let i = dayjs().year(); i >= firstYear; i--) {
          years.push(i);
        }
        return years;
      },
    }

  };
</script>
