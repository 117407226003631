<template>
  <v-container
    fluid
    style="display: flex; flex-flow: column; height: 100%;"
  >
    <v-row
      style="flex: 0 1 auto"
    >
      <v-col cols="12" md="12">
        <div
          class="pa-2 text-h1 text-center"
          v-t="softwareTitle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="px-6"
      >
        <v-card
          elevation="8"
          class="my-6"
        >
          <v-tabs
            show-arrows
            icons-and-text
            grow
            v-model="tab"
          >
            <v-tab>
              <v-icon>mdi-account</v-icon>
              <div class="calls-super py-1" v-t="'account.login'"></div>
            </v-tab>
            <v-tab v-if="softwareEnvironment !== 'production'">
              <v-icon>mdi-account-outline</v-icon>
              <div class="calls-super py-1" v-t="'account.register'"></div>
            </v-tab>
            <v-tab-item>
              <login></login>
            </v-tab-item>
            <v-tab-item v-if="softwareEnvironment !== 'production'">
              <register></register>
            </v-tab-item>
          </v-tabs>
        </v-card>

        <v-card
          elevation="8"
          class="my-6"
        >
          <v-card-text align="center">
            <v-btn
              text
              plain
              href="/?locale=hu"
            >
              <country-flag country='hu' size='normal'/>
            </v-btn>
            <v-btn
              text
              plain
              href="/?locale=en"
            >
              <country-flag country='gb' size='normal'/>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <presentation></presentation>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Login from './Login';
  import Register from './Register';
  import Presentation from './Presentation';
  import software from '../../mixins/software';

  export default {
    mixins: [
      software
    ],
    components: {
      Login,
      Register,
      Presentation,
    },

    data() {
      return {
        tab: 0,
      };
    },
  };
</script>
