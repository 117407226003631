import { newDate } from './dates';
import dayjs from 'dayjs';

// eslint-disable-next-line camelcase
const spendingsFactory = (data) => data.map(({ occurs_at, ...rest }) => ({ ...rest, occursAt: newDate(occurs_at) }));
const spendingsAttributesFor = (spendings) => spendings.map(spending => ({
  ...spending,
  occurs_at: dayjs(spending.occursAt).toISOString() || dayjs().toISOString()
}));

export {
  spendingsFactory,
  spendingsAttributesFor
};
