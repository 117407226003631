<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        dark
        large
        v-bind="attrs"
        v-on="on"
      >
        <v-avatar
          item
          size="32px"
        >
          <v-icon>mdi-account</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="selectCurrentUser">
        <v-list-item-title>{{ email }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout" class="logout">
        <v-list-item-title v-t="'menu.logout'"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    computed: {
      email() {
        return this.$store.state.authentication.email;
      }
    },
    methods: {
      logout() {
        return this.$store.dispatch('authentication/logout');
      },
      selectCurrentUser() {
        this.$store.commit('settings/selectUser', { timestamp: new Date(), ...this.$store.state.authentication });
      },
    },
  };
</script>
