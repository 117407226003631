<template>
  <v-container class="pa-0" fluid>
    <v-list class="settings-calendar-room-list">
      <v-list-group
        :key="calendar.id"
        eager
        :disabled="!editing"
        :append-icon="editing ? 'mdi-chevron-down' : ''"
        v-for="(calendar, calendarIdex) in calendars"
      >
        <template v-slot:activator>
          <calendar-list-item
            :calendars="calendars"
            :editing="editing"
            v-model="calendars[calendarIdex]"
          ></calendar-list-item>
        </template>
        <v-lazy
          v-for="(room, index) in calendar.rooms"
          :key="room.id"
        >
          <room-list-item
            :calendar="calendar"
            v-model="calendar.rooms[index]"
          ></room-list-item>
        </v-lazy>
      </v-list-group>
      <v-list-group
        v-if="!editing"
        append-icon=""
        @click="selectCalendar(newCalendar)"
      >
        <template v-slot:activator>
          <v-row>
            <v-col cols="10" lg="10" md="10" sm="10">
              <span v-t="'settings.calendars.new'"></span>
            </v-col>
          </v-row>
        </template>
      </v-list-group>
    </v-list>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          @click="save"
          color="green darken-1"
          text
          v-t="'settings.save'"
          :disabled="!dirty"
        ></v-btn>
        <v-btn
          @click="cancel"
          color="yellow darken-1"
          text
          v-t="'settings.cancel'"
        ></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import RoomListItem from './RoomListItem';
  import CalendarListItem from './CalendarListItem';

  export default {
    components: {
      RoomListItem,
      CalendarListItem,
    },
    props: {
      editing: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        newCalendar: { timestamp: new Date(), title: '' },
        allIds: this.$store.getters['authentication/getAllIds'],
      };
    },
    computed: {
      dirty() {
        return JSON.stringify(this.$store.getters['authentication/getAllIds']) !== JSON.stringify(this.allIds);
      },
      calendars() {
        return this.$store.state.authentication.company.calendars;
      },
      company() {
        return this.$store.state.authentication.company;
      },
    },
    methods: {
      save() {
        this.$store.dispatch('settings/saveCalendars', this.calendars)
          .then(() => {
            this.allIds = this.$store.getters['authentication/getAllIds'];
          });
      },
      cancel() {
        this.$store.dispatch('authentication/initialize');
      },
      selectCalendar(calendar) {
        this.$store.commit('settings/selectCalendar', { timestamp: new Date(), ...calendar });
      },
    },
  };
</script>
