import { paginatedFetch } from '../../util/paginatedFetch';
import dayjs from 'dayjs';

const state = {
  messages: [],
  messagesRequest: false,
};

const getters = {
};

const mutations = {
  setMessages: (state, payload) => {
    state.messages = payload;
  },
  setMessagesRequest: (state, payload) => {
    state.messagesRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setMessages', []);
    commit('setMessagesRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.ntak.messages.url,
      {
        query: {
          from_date: dayjs().subtract(2, 'months').format('YYYY-MM-DD'),
          to_date: dayjs().format('YYYY-MM-DD'),
        }
      },
      (results) => commit('setMessages', state.messages.concat(results)),
      () => commit('setMessagesRequest', false)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
