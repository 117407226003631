import axios from 'axios';

const controllers = {};

export function paginatedFetch(url, params, setFn, endFn = () => {}, page = 1) {
  controllers[url]?.abort();
  controllers[url] = new AbortController();

  return axios
    .post(url, { query: { ...params.query || {}, page } }, { signal: controllers[url].signal })
    .then(response => {
      const { data } = response;
      setFn(data.results);
      if (data.results.length === data.page_size) {
        paginatedFetch(url, params, setFn, endFn, page + 1);
      } else {
        endFn();
      }
    })
    .catch(error => axios.isCancel(error) || console.log(error));
}
