import axios from 'axios';

const state = {
  arrivingGuests: null,
  arrivingGuestsRequest: false,
  guestNights: null,
  guestNightsRequest: false,
};

const getters = {
  arrivingGuestsTable: state => countKey => {
    const table = {};
    state.arrivingGuests?.forEach((guest) => {
      const key = guest.year + '-' + guest.month;
      const value = guest[countKey];

      if (!value) {
        return;
      }
      if (!table[key]) {
        table[key] = {
          year: guest.year,
          month: guest.month,
          total: 0
        };
      }
      table[key][guest.country_code || 'unknown'] = value;
      table[key].total += value;
    });
    return Object.values(table);
  },

  guestNightsFor: state => roomType => {
    const table = {};
    state.guestNights?.forEach((guest) => {
      if (guest.room_type !== roomType) {
        return;
      }
      const key = guest.year + '-' + guest.month;
      const value = guest.nights;

      if (!value) {
        return;
      }
      if (!table[key]) {
        table[key] = {
          year: guest.year,
          month: guest.month,
          total: 0
        };
      }
      table[key][guest.country_code || 'unknown'] = value;
      table[key].total += value;
    });
    return Object.values(table);
  },

  arrivingGuestsCountries: state => {
    return [...new Set(state.arrivingGuests?.map(guest => guest.country_code))].sort();
  },
  guestNightsCountries: state => {
    return [...new Set(state.guestNights?.map(guest => guest.country_code))].sort();
  },
  guestNightsRoomTypes: state => {
    return [...new Set(state.guestNights?.map(guest => guest.room_type))].sort();
  },
};

const mutations = {
  setArrivingGuests(state, arrivingGuests) {
    state.arrivingGuests = arrivingGuests;
  },
  setArrivingGuestsRequest(state, arrivingGuestsRequest) {
    state.arrivingGuestsRequest = arrivingGuestsRequest;
  },
  setGuestNights(state, guestNights) {
    state.guestNights = guestNights;
  },
  setGuestNightsRequest(state, guestNightsRequest) {
    state.guestNightsRequest = guestNightsRequest;
  }
};

const actions = {
  fetchArrivingGuests({ commit, rootState }, { year, month }) {
    const user = rootState.authentication;
    commit('setArrivingGuestsRequest', true);
    axios.post(user.resources.queries.arriving_guests.url, { query: { year, month } })
      .then((response) => commit('setArrivingGuests', response.data.results))
      .catch((error) => console.log(error))
      .finally(() => commit('setArrivingGuestsRequest', false));
  },
  fetchGuestNights({ commit, rootState }, { year, month }) {
    const user = rootState.authentication;
    commit('setGuestNightsRequest', true);
    axios.post(user.resources.queries.guest_nights.url, { query: { year, month } })
      .then((response) => commit('setGuestNights', response.data.results))
      .catch((error) => console.log(error))
      .finally(() => commit('setGuestNightsRequest', false));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
