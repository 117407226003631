<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog
    @click:outside="dialogClose"
    max-width="1000px"
    :fullscreen="$vuetify.breakpoint.mobile"
    scrollable
    v-model="value"
    persistent
    @keydown.esc="dialogClose"
  >
    <!-- eslint-enable vue/no-mutating-props -->
    <v-card :class="`${type}-dialog dialog`">
      <dialog-title
        :close="dialogClose"
        :toggle="() => toggle = !toggle"
        :item="item"
        :title="title"
        :dirty="dirty"
        :type="type"
        :klass="klass"
      ></dialog-title>
      <v-expand-transition>
        <v-card-text v-show="toggle">
          <slot name="body"></slot>
        </v-card-text>
      </v-expand-transition>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import DialogTitle from './DialogTitle';

  export default {
    components: {
      DialogTitle,
    },
    props: {
      title: {
        type: String
      },
      type: {
        type: String,
      },
      close: {
        type: Function
      },
      item: {
        type: Object
      },
      klass: {
        type: String
      },
      dirty: {
        type: Boolean
      },
      value: {
        type: Boolean
      },
    },
    data() {
      return {
        toggle: true,
      };
    },
    methods: {
      dialogClose() {
        if (!this.dirty) {
          this.close();
        } else {
          this.$confirm(this.$t('messages.confirm_close'))
            .then(response => {
              if (response) {
                this.close();
              }
            });
        }
      },
    },
  };
</script>
