<template>
  <v-list-item
    @click="selectPerson"
    two-line
  >
    <v-list-item-content
    >
      <v-list-item-title>
        <span class="subtitle-2">{{item.name}}</span>
        <span class="grey--text">{{item.address}}</span>
      </v-list-item-title>
      <v-list-item-subtitle>{{ item.emails.join(', ') }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ item.phones.join(', ') }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    methods: {
      selectPerson() {
        this.$emit('click', this.item);
      },
    },
  };
</script>
