<template>
  <v-list>
    <v-list-item
            :key="user.id"
            @click="selectUser(user)"
            v-for="user in users"
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-icon>mdi-{{ user.role === 'regular' ? 'account' : 'account-supervisor' }}</v-icon>
          <span>
          {{ user.email }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
            @click="newUser"
            v-if="company.auth.indexOf('update') !== -1"
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-icon>mdi-plus-thick</v-icon>
          <span v-t="'settings.account.new'"></span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    computed: {
      users() {
        return this.$store.state.authentication.company.users;
      },
      company() {
        return this.$store.state.authentication.company;
      },
    },
    methods: {
      selectUser(user) {
        this.$store.commit('settings/selectUser', { timestamp: new Date(), ...user });
      },
      newUser() {
        this.$store.commit('settings/selectUser', { timestamp: new Date() });
      },
    },
  };
</script>
