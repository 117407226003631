<template>
  <v-list-item
    @click="selectReservation"
    two-line
    dense
  >
    <v-list-item-content>
      <v-list-item-title style="display: flex">
        <v-icon :color="reservationStyle(item)">mdi-checkbox-blank-circle</v-icon>
        <v-breadcrumbs :items="crumbs" class="subtitle-2 pa-0"></v-breadcrumbs>
      </v-list-item-title>
      <v-list-item-subtitle> {{ reservationTitle(item) }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import reservationMixin from '../../mixins/reservations';
  export default {
    props: {
      item: {
        type: Object
      },
      dateFormat: {
        type: String
      },
      arrivals: {
        type: Boolean,
        default: true
      }
    },
    mixins: [reservationMixin],
    computed: {
      crumbs() {
        const room = this.$store.getters['authentication/getRoomById'](this.item.room_id);
        return [
          { text: this.$d(this.arrivals ? this.item.startsAt : this.item.endsAt, this.dateFormat ?? 'hoursAndMinutes') },
          { text: room.calendar.title },
          { text: room.title }
        ];
      },
    },
    methods: {
      selectReservation() {
        this.$emit('click', this.item);
        this.$store.dispatch('calendar/selectReservation', this.item);
      },
    },
  };
</script>

<style scoped>
.v-breadcrumbs {
  padding-left: 0;
}
</style>
