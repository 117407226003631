import { paginatedFetch } from '../../util/paginatedFetch';

const state = {
  roomsWithoutAccommodation: [],
  roomsRequest: false,
};

const getters = {};

const mutations = {
  setRoomsWithoutAccommodation: (state, payload) => {
    state.roomsWithoutAccommodation = payload;
  },
  setRoomsRequest: (state, payload) => {
    state.roomsRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setRoomsWithoutAccommodation', []);
    commit('setRoomsRequest', true);
    const user = rootState.authentication;
    const roomTypes = rootState.data.realRoomTypes;
    paginatedFetch(
      user.resources.queries.rooms.url,
      { query: { exclude_accommodations: true, status: 'active', room_type: roomTypes } },
      (results) => commit('setRoomsWithoutAccommodation', state.roomsWithoutAccommodation.concat(results)),
      () => commit('setRoomsRequest', false)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
