import axios from 'axios';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { router } from '../../routes/router';

const DASHBOARD_URL = '/dashboard';
const LOGIN_URL = '/login';
const API_LOGIN_URL = '/api/login';
const API_SIGNUP_URL = '/api/signup';
const API_LOGOUT_URL = '/api/logout';

const state = {
  loggedIn: false,
  email: '',
  url: '',
  error: null,
  registrationErrors: {},
  company: {},
};

const getters = {
  getAllRooms: state => state.company.calendars.flatMap(calendar => calendar.rooms),
  getRoomsFor: state => calendarId => state.company.calendars
    .find(calendar => calendar.id === calendarId)
    .rooms
    .filter(room => room.status === 'active'),
  getRoomById: state => roomId => {
    if (!state.company.calendars) {
      return null;
    }
    const calendar = state.company.calendars.find(c => c?.rooms?.find(r => r.id === roomId));
    const room = calendar?.rooms?.find(r => r.id === roomId);
    return { ...room, calendar };
  },
  getCalendarById: state => calendarId => state.company.calendars.find(c => c.id === calendarId),
  getRoomsWithHeaders: state => {
    if (!state.company.calendars) {
      return [];
    }

    return state.company.calendars.flatMap(c =>
      [
        { header: c.title },
        ...c.rooms?.filter(room => room.status === 'active').map(room => ({ text: room.title, value: room.id })) || []]
    );
  },
  getAllIds: state => state.company.calendars.flatMap(c => [`c-${c.id}`, ...c.rooms?.map(room => `r-${room.id}`) || []])
};

const mutations = {
  login: (state, { email, company, id, resources, url, redirect, role }) => {
    state.email = email;
    state.company = company;
    state.id = id;
    state.resources = resources;
    state.url = url;
    state.loggedIn = true;
    state.role = role;
    if (redirect) {
      document.location.href = DASHBOARD_URL;
    }
  },
  error: (state, payload) => {
    state.error = payload;
  },
  setRegistrationErrors: (state, payload) => {
    state.registrationErrors = payload;
  },
  logout: (state) => {
    state.email = '';
    state.loggedIn = false;
    state.company = {};
    state.url = '';
  },
  updateCompany: (state, payload) => {
    state.company = Object.assign({}, state.company, payload);
  },
  updateCalendarTitle: (state, { id, title }) => {
    const index = state.company.calendars.findIndex(c => id === c.id);
    if (index === -1) {
      state.company.calendars.push({ id, title });
    } else {
      state.company.calendars[index].title = title;
    }
  },
  updateRoom: (state, { calendar, ...room }) => {
    const roomIndex = calendar.rooms.findIndex(r => r.id === room.id);
    calendar.rooms.splice(roomIndex, 1, room);
  },
  removeRoom: (state, { id, calendar }) => {
    const roomIndex = calendar.rooms.findIndex(room => room.id === id);
    calendar.rooms.splice(roomIndex, 1);
  },
  updateUser: (state, payload) => {
    const userIndex = state.company.users.findIndex(user => user.id === payload.id);
    state.company.users.splice(userIndex, 1, payload);
  },
  removeUser: (state, { id }) => {
    const userIndex = state.company.users.findIndex(user => user.id === id);
    state.company.users.splice(userIndex, 1);
  },
  removeCalendar: (state, { id }) => {
    const calendarIndex = state.company.calendars.findIndex(calendar => calendar.id === id);
    state.company.calendars.splice(calendarIndex, 1);
  },
};

const actions = {
  initialize: ({ commit }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.url) {
      return axios
        .get(user.url)
        .then(response => commit('login', response.data))
        .catch(response => {
          localStorage.removeItem('user');
          commit('error', response.response.data.error);
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject();
        });
    } else {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
  },
  login: ({ commit }, { email, password }) => {
    commit('error', '');
    axios
      .post(API_LOGIN_URL, { user: { email, password } })
      .then(response => handleLogin(commit, response, email))
      .catch(response => {
        localStorage.removeItem('user');
        commit('error', response.response.data.error);
      });
  },
  logout: ({ commit }) => {
    axios
      .delete(API_LOGOUT_URL)
      .then(() => {
        commit('logout');
        localStorage.removeItem('user');
        router.push(LOGIN_URL).catch(() => {});
      });
  },
  // eslint-disable-next-line camelcase
  register: ({ commit }, { email, password, name, title, recaptcha_token }) => {
    commit('setRegistrationErrors', {});
    axios
      .post(API_SIGNUP_URL, { registration: { email, password, name, title, recaptcha_token } })
      .then(response => {
        commit('setRegistrationErrors', {});
        handleLogin(commit, response, email);
      })
      .catch(response => commit('setRegistrationErrors', response.response.data.errors));
  },
};

function handleLogin(commit, response, email) {
  const token = jwt_decode(response.headers.authorization.replace(/Bearer /, ''));
  if (token.user_id) {
    const { url } = response.data;
    localStorage.setItem('user', JSON.stringify({ url, email, ...token }));
    document.location.href = DASHBOARD_URL;
  } else {
    commit('error', 'Something went wrong.');
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
