<template>
  <v-tooltip
    :disabled="$vuetify.breakpoint.mobile"
    bottom
    close-delay="200"
    open-delay="300"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        :style="style"
        class="reservation white--text"
        :class="{'pt-1': !!base}"
        @click.stop="handleClick"
        v-bind="attrs"
        v-on="on"
      >
        <div
          :class="reservationStyle(reservation)"
          class="ma-0 pl-1 elevation-3 v-event v-event-start v-event-end"
          v-ripple
        >{{ reservationTitle(reservation) }}</div>
      </div>
    </template>
    <div>
      <h4>
        {{ reservation.title }}
      </h4>
      <div class="subtitle-2">
        {{ $d(reservation.startsAt) }}
        -
        {{ $d(reservation.endsAt) }}
      </div>
      <div class="body-2" style="white-space: pre">{{ reservation.note }}</div>
    </div>
  </v-tooltip>
</template>

<script>
  import reservationMixin from '../../mixins/reservations';

  export default {
    props: {
      reservation: {
        type: Object
      },
      base: {
        type: Array,
      },
      month: {
        type: Number
      }
    },
    mixins: [reservationMixin],
    computed: {
      style() {
        if (!this.base) {
          return '';
        }
        return {
          left: `${this.left}px`,
          width: `${this.width}px`,
        };
      },
      left() {
        const { startsAt } = this.reservation;
        const offset = (this.base[1] - this.base[0]) / 2;

        if (this.month === (startsAt.getMonth() + 1)) {
          return this.base[startsAt.getDate() - 1] - this.base[0] + offset;
        } else {
          return 1;
        }
      },
      width() {
        const { endsAt } = this.reservation;
        const offset = (this.base[1] - this.base[0]) / 2;

        if (this.month === (endsAt.getMonth() + 1)) {
          const width = this.base[endsAt.getDate()] - this.base[0] - this.left - offset - 3;
          return width <= 0 ? offset : width;
        } else {
          return this.base[this.base.length - 1] - this.base[0] - this.left - 3;
        }
      },
    },
    methods: {
      handleClick() {
        this.$store.dispatch('calendar/selectReservation', this.reservation);
      },
    },
  };
</script>

<style lang="scss" scoped>
.reservation {
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  vertical-align: center;

  .pl-1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
