<template>
  <dialog-container
    v-model="open"
    :close="reallyClose"
    :item="room"
    :title="title"
    :dirty="dirty"
    type="room"
    klass="green"
    ref="dialog"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field
              :error-messages="errors.title"
              :label="$t('settings.rooms.title')"
              :readonly="readonly"
              dense
              filled
              prepend-icon="mdi-subtitles"
              v-model="title"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="calendars"
              :label="$t('settings.rooms.calendar')"
              :readonly="readonly"
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-calendar"
              return-object
              v-model="calendar"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field
              :error-messages="errors.building"
              :label="$t('settings.rooms.building')"
              :readonly="readonly"
              dense
              filled
              prepend-icon="mdi-subtitles"
              v-model="building"
              :placeholder="calendar?.title"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="icons"
              :label="$t('settings.rooms.icon')"
              :readonly="readonly"
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-alpha-i-circle-outline"
              return-object
              v-model="icon"
            >
              <template v-slot:selection="{ item }">
                <v-icon>mdi-{{ item }}</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-icon>mdi-{{ item }}</v-icon>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <translated-select
              prefix="settings.rooms.statuses"
              :items="$store.state.data.roomStatuses"
              :label="$t('settings.rooms.status')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-list-status"
              :error-messages="errors.status"
              v-model="status"
            >
            </translated-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="$store.state.accommodations.accommodations"
              :label="$t('settings.rooms.accommodation')"
              :readonly="readonly"
              clearable
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-bed-outline"
              v-model="ntakAccommodationId"
              :error-messages="errors.ntak_accommodation_id"
              v-if="ntakEnabled"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <translated-select
              :items="$store.state.data.roomTypes"
              :label="$t('settings.rooms.room_type')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-list-status"
              prefix="settings.rooms.room_types"
              postfix="title"
              :has-description="true"
              :error-messages="errors.room_type"
              v-model="roomType"
              v-if="ntakAccommodationId && ntakEnabled"
            >
            </translated-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="numbers"
              :label="$t('settings.rooms.single_bed_count')"
              :readonly="readonly"
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-bed"
              return-object
              v-model="singleBedCount"
              :error-messages="errors.single_bed_count"
              v-if="ntakAccommodationId && ntakEnabled"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="numbers"
              :label="$t('settings.rooms.double_bed_count')"
              :readonly="readonly"
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-bed-king"
              return-object
              v-model="doubleBedCount"
              :error-messages="errors.double_bed_count"
              v-if="ntakAccommodationId && ntakEnabled"
            >
            </v-select>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-select
              :items="numbers"
              :label="$t('settings.rooms.extra_bed_count')"
              :readonly="readonly"
              filled
              item-text="title"
              item-value="id"
              prepend-icon="mdi-bed-outline"
              return-object
              v-model="extraBedCount"
              :error-messages="errors.extra_bed_count"
              v-if="ntakAccommodationId && ntakEnabled"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'settings.rooms.save'"
        :disabled="!dirty"
      ></v-btn>

      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="room?.url && !readonly"
        v-t="'settings.rooms.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'settings.rooms.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import TranslatedSelect from '../../shared/TranslatedSelect';
  import DialogContainer from '../../shared/DialogContainer';

  export default {
    components: {
      TranslatedSelect,
      DialogContainer,
    },
    data() {
      return {
        open: false,
        title: '',
        calendar: null,
        icon: '',
        status: '',
        singleBedCount: 0,
        doubleBedCount: 0,
        extraBedCount: 0,
        building: 0,
        roomType: '',
        ntakAccommodationId: null,
        numbers: [...Array(11).keys()],
        icons: [
          'home-floor-0',
          'home-floor-1',
          'home-floor-2',
          'home-floor-3',
          'home',
          'home-group',
          'home-modern',
          'office-building',
          'bed',
          'bunk-bed',
          'bed-outline',
          'rv-truck',
          'tent'
        ],
      };
    },
    computed: {
      dirty() {
        const room = this.room || {};
        return !(this.title === room.title &&
          this.icon === room.icon &&
          this.building === room.building &&
          this.status === room.status &&
          this.roomType === room.room_type &&
          this.singleBedCount === room.single_bed_count &&
          this.doubleBedCount === room.double_bed_count &&
          this.calendar.id === room.calendar.id &&
          this.ntakAccommodationId === room.ntak_accommodation_id &&
          this.extraBedCount === room.extra_bed_count);
      },
      calendars() {
        return this.$store.state.authentication.company.calendars;
      },
      ntakEnabled() {
        return this.$store.state.authentication.company.ntak_enabled;
      },
      room() {
        return this.$store.state.settings.selectedRoom;
      },
      errors() {
        return this.$store.state.settings.roomErrors;
      },
      readonly() {
        if (!this.room) {
          return;
        }
        return this.room.url && this.room.auth?.indexOf('update') === -1;
      },
    },
    methods: {
      save() {
        this.$store.dispatch('settings/saveRoom', {
          url: this.room.url,
          calendar: this.calendar,
          data: {
            title: this.title,
            calendar_id: this.calendar.id,
            icon: this.icon,
            status: this.status,
            building: this.building,
            single_bed_count: this.singleBedCount,
            double_bed_count: this.doubleBedCount,
            extra_bed_count: this.extraBedCount,
            room_type: this.roomType,
            ntak_accommodation_id: this.ntakAccommodationId,
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('settings.rooms.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('settings/deleteRoom', {
                url: this.room.url,
                id: this.room.id,
                calendar: this.calendar,
              });
            }
          });
      },
      reallyClose() {
        this.open = false;
        this.$store.commit('settings/setRoomErrors', []);
      },
    },
    watch: {
      room() {
        if (!this.room) {
          this.open = false;
          return;
        }

        this.open = true;
        this.title = this.room.title;
        this.icon = this.room.icon;
        this.building = this.room.building;
        this.status = this.room.status;
        this.roomType = this.room.room_type;
        this.singleBedCount = this.room.single_bed_count;
        this.doubleBedCount = this.room.double_bed_count;
        this.extraBedCount = this.room.extra_bed_count;
        this.ntakAccommodationId = this.room.ntak_accommodation_id;
        this.calendar = this.room.calendar;
      },
    }
  };
</script>
