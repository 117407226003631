const compareByKey = (a1, a2, key) => squeeze(a1, key) === squeeze(a2, key);
const squeeze = (a, key) => JSON.stringify(a?.map(x => x[key]).concat().sort());

const compareGuests = (a1, a2) => ['person_id', '_destroy', 'tourist_tax'].every(key => compareByKey(a1, a2, key));
const compareDates = (d1, d2) => d1?.toISOString() === d2?.toISOString();
const compareArrays = (a1, a2) => JSON.stringify(a1?.concat()?.sort()) === JSON.stringify(a2?.concat()?.sort());
const compareCountryCode = (a1, a2, defaultCode) => a2 ? a1 === a2 : a1 === defaultCode;

export {
  compareGuests,
  compareDates,
  compareArrays,
  compareCountryCode
};
