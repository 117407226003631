<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <div>
          <span class="text-h6">
            {{ $t(`search.${type}`) }}
          </span>
          <span class="text--secondary" v-if="!open">
            ({{ items.length }} {{ $t('search.hits')}})
          </span>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-list
        subheader
        v-if="items.length"
      >
        <template v-for="item in items">
          <event-list-item
            v-if="type === 'events'"
            :item="item"
            :key="item.id"
            date-format="long"
          ></event-list-item>
          <reservation-list-item
            v-if="type === 'reservations'"
            :item="item"
            :key="item.id"
            date-format="long"
          ></reservation-list-item>
          <person-list-item
            v-if="type === 'contacts'"
            :item="item"
            :key="item.id"
            @click="$store.dispatch('contacts/selectPerson', $event)"
          ></person-list-item>
        </template>
      </v-list>
      <v-card-subtitle v-else-if="request" v-t="'search.one_moment'"></v-card-subtitle>
      <v-card-subtitle v-else v-t="'search.no_items'"></v-card-subtitle>
    </v-expansion-panel-content>
    <v-progress-linear
      indeterminate
      absolute
      bottom
      :active="request"
    ></v-progress-linear>
  </v-expansion-panel>
</template>

<script>
  import EventListItem from '../shared/EventListItem';
  import PersonListItem from '../shared/PersonListItem';
  import ReservationListItem from '../shared/ReservationListItem';

  export default {
    components: {
      EventListItem,
      PersonListItem,
      ReservationListItem,
    },
    props: {
      type: {
        type: String
      },
      items: {
        type: Array
      },
      request: {
        type: Boolean,
        default: false
      }
    },
  };
</script>
