<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            :error-messages="errors.title"
            :label="$t('settings.company.title')"
            filled
            prepend-icon="mdi-subtitles"
            v-model="title"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            :error-messages="errors.city"
            :label="$t('settings.company.city')"
            filled
            prepend-icon="mdi-subtitles"
            v-model="city"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            :error-messages="errors.zipcode"
            :label="$t('settings.company.zipcode')"
            filled
            prepend-icon="mdi-subtitles"
            v-model="zipcode"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-text-field
            :error-messages="errors.tax_number"
            :label="$t('settings.company.tax_number')"
            filled
            prepend-icon="mdi-subtitles"
            v-model="taxNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <translated-select
            :items="$store.state.data.locales"
            :label="$t('settings.company.locale')"
            :error-messages="errors.locale"
            filled
            prepend-icon="mdi-translate"
            prefix="locale"
            v-model="locale"
          ></translated-select>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-select
            :items="countries"
            :label="$t('settings.company.country_code')"
            filled
            item-text="name"
            item-value="id"
            prepend-icon="mdi-flag"
            v-model="countryCode"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="6" sm="12">
          <time-picker
            :label="$t('settings.company.reservation.start')"
            prepend-icon="mdi-clock-outline"
            v-model="reservationStartTime"
          ></time-picker>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <time-picker
            :label="$t('settings.company.reservation.end')"
            prepend-icon="mdi-clock-outline"
            v-model="reservationEndTime"
          ></time-picker>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="12">
          <time-picker
            :label="$t('settings.company.event.start')"
            prepend-icon="mdi-clock-outline"
            v-model="eventStartTime"
          ></time-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="4" md="6" sm="12">
          <v-switch
            prepend-icon="mdi-chess-rook"
            v-model="ntakEnabled"
            :label="$t('settings.company.ntak_enabled')"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            @click="save"
            color="green darken-1"
            text
            v-t="'settings.save'"
          ></v-btn>

          <v-btn
            @click="cancel"
            color="yellow darken-1"
            text
            v-t="'settings.cancel'"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import TimePicker from '../../shared/pickers/TimePicker';
  import TranslatedSelect from '../../shared/TranslatedSelect';

  export default {
    components: {
      TimePicker,
      TranslatedSelect,
    },
    data() {
      const company = this.$store.state.authentication.company;
      return {
        title: company.title,
        city: company.city,
        zipcode: company.zipcode,
        taxNumber: company.tax_number,
        countryCode: company.country_code,
        locale: { value: company.locale },
        eventStartTime: company.config.event.start,
        reservationStartTime: company.config.reservation.start,
        reservationEndTime: company.config.reservation.end,
        ntakEnabled: company.ntak_enabled,
      };
    },
    computed: {
      company() {
        return this.$store.state.authentication.company;
      },
      errors() {
        return this.$store.state.settings.companyErrors;
      },
      countries() {
        return this.$store.state.countries;
      },
    },
    methods: {
      save() {
        const data = {
          title: this.title,
          locale: this.locale,
          country_code: this.countryCode,
          zipcode: this.zipcode,
          city: this.city,
          tax_number: this.taxNumber,
          ntak_enabled: this.ntakEnabled,
          config: {
            reservation: {
              start: this.reservationStartTime,
              end: this.reservationEndTime,
            },
            event: {
              start: this.eventStartTime,
            }
          },
        };

        this.$store.dispatch('settings/saveCompany', data);
      },
      cancel() {
        const company = this.$store.state.authentication.company;
        this.title = company.title;
        this.countryCode = company.country_code;
        this.locale = { value: company.locale };
      },
    },
  };
</script>
