const newDate = (dateStr) => dateStr ? new Date(dateStr) : null;
const daysOfMonth = (year, month, holidays, locale) => {
  const today = new Date();

  const length = new Date(year, month, 0).getDate();
  return Array.from({ length: length }, (x, i) => {
    const date = new Date(year, month - 1, i + 1);
    const day = today.isSameDay(date) ? 'today' : isHoliday(date, holidays);

    return {
      date,
      day,
      title: title(date, locale),
      number: number(date, locale)
    };
  });
};

const isHoliday = (date, holidays) => {
  switch (holidays.find(holiday => holiday.isAt.isSameDay(date))?.holiday) {
    case true:
      return 'weekend';
    case false:
      return 'weekday';
    default:
      return date.getDay() % 6 === 0 ? 'weekend' : 'weekday';
  }
};

const title = (date, locale) => date.toLocaleDateString(locale, { day: 'numeric' });
const number = (date, locale) => date.toLocaleDateString(locale, { weekday: 'narrow' });

export { newDate, daysOfMonth };
