import axios from 'axios';
import { reservationFactory } from '../../util/reservation';
import { eventFactory } from '../../util/event';

const state = {
  reservations: null,
  events: null,
};

const getters = {};

const mutations = {
  setReservations: (state, payload) => {
    state.reservations = payload;
  },
  setEvents: (state, payload) => {
    state.events = payload;
  },
};

const actions = {
  initialize: ({ commit, rootGetters, rootState }, { from, to }) => {
    commit('setReservations', null);
    commit('setEvents', null);
    const user = rootState.authentication;

    const endOfFrom = new Date(from);
    endOfFrom.setHours(23);
    endOfFrom.setMinutes(59);
    endOfFrom.setSeconds(59);
    const data = {
      query: {
        from_date: from,
        to_date: to,
        statuses: rootState.data.liveReservationStatuses
      }
    };
    axios
      .post(user.resources.queries.reservations.url, data)
      .then(response => commit('setReservations', response.data.results.map(reservationFactory)));
    axios
      .post(user.resources.queries.events.url, { query: { from_date: from, to_date: endOfFrom } })
      .then(response => commit('setEvents', response.data.results.map(eventFactory)));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
