import { paginatedFetch } from '../../util/paginatedFetch';
import axios from 'axios';
import dayjs from 'dayjs';

const state = {
  certificates: [],
  certificatesRequest: false,
};

const getters = {
  getAvailableCertificates: state => includeId => state.certificates?.filter(cert => cert.status === 'available' || cert.id === includeId),
};

const mutations = {
  setCertificates: (state, payload) => {
    state.certificates = payload;
  },
  setCertificatesRequest: (state, payload) => {
    state.certificatesRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setCertificates', []);
    commit('setCertificatesRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.ntak.certificates.url,
      { query: {} },
      (results) => commit('setCertificates', state.certificates.concat(results.map(certificateFactory))),
      () => commit('setCertificatesRequest', false)
    );
  },
  createNewPair: ({ commit, dispatch, rootState }, { accommodation }) => {
    axios
      .post(accommodation.certificate_pair_creations.url)
      .then(() => setTimeout(() => {
        dispatch('initialize');
        dispatch('accommodations/selectAccommodation', accommodation, { root: true });
      }, 5000))
      .catch(response => commit('addMessage', { message: response.response.data.errors.company.join(', '), type: 'error' }, { root: true }));
  },
  download: ({ commit, rootState, state }, { url }) => {
    axios.get(url).then(response => {
      // eslint-disable-next-line camelcase
      const { type, csr, created_at } = response.data;
      const filename = `ntak-${type}-${dayjs(created_at).format('YYYYMMDDHHmmss')}.csr`;
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csr));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
  },
  upload: async ({ commit, rootState, state }, { item, file }) => {
    const text = await readFile(file);
    return axios.patch(item.url, { pem: text })
      .catch((response) => {
        state.certificates.forEach(cert => {
          cert.errors = null;
        });
        const certificate = state.certificates.find(cert => cert.id === item.id);
        Object.assign(certificate, { errors: response.response.data.errors.pem });

        commit('setCertificates', state.certificates);
        commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true });
        commit('addMessage', { message: response.response.data.errors.pem.join('<br/>'), type: 'warning' }, { root: true });
      });
  },
};

async function readFile(file) {
  if (!file) {
    return null;
  }
  return await new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsText(file);
  });
}

function certificateFactory(item) {
  const title = item.updated_at;
  return { title, errors: null, ...item };
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
