import { paginatedFetch } from '../../util/paginatedFetch';
import { reservationFactory } from '../../util/reservation';
import { eventFactory } from '../../util/event';
import { personFactory } from '../../util/person';
import axios from 'axios';

const state = {
  people: [],
  peopleRequest: false,
  events: [],
  eventsRequest: false,
  reservations: [],
  reservationsRequest: false,
  quickSearchResults: { people: [], events: [], reservations: [] },
  quickSearchResultsRequest: false,
};

const getters = {};

const mutations = {
  setReservations: (state, payload) => {
    state.reservations = payload;
  },
  setEvents: (state, payload) => {
    state.events = payload;
  },
  setPeople: (state, payload) => {
    state.people = payload;
  },
  setEventsRequest: (state, payload) => {
    state.eventsRequest = payload;
  },
  setPeopleRequest: (state, payload) => {
    state.peopleRequest = payload;
  },
  setReservationsRequest: (state, payload) => {
    state.reservationsRequest = payload;
  },
  setQuickSearchResults: (state, { type, items }) => {
    state.quickSearchResults[type] = items;
  },
  clearQuickSearchResults: (state) => {
    state.quickSearchResults = { people: [], events: [], reservations: [] };
  },
  setQuickSearchResultsRequest: (state, payload) => {
    state.quickSearchResultsRequest = payload;
  },
};

const searchPeople = ({ user, data, commit, state }) => {
  paginatedFetch(
    user.resources.queries.people.url,
    data,
    (results) => commit('setPeople', state.people.concat(results.map(personFactory))),
    () => {
      commit('setPeopleRequest', false);
      searchReservations({ user, data, commit, state });
    }
  );
};

const searchReservations = ({ user, data, commit, state }) => {
  paginatedFetch(
    user.resources.queries.reservations.url,
    data,
    (results) => commit('setReservations', state.reservations.concat(results.map(reservationFactory))),
    () => {
      commit('setReservationsRequest', false);
      searchEvents({ user, data, commit, state });
    }
  );
};

const searchEvents = ({ user, data, commit, state }) => {
  paginatedFetch(
    user.resources.queries.events.url,
    data,
    (results) => commit('setEvents', state.events.concat(results.map(eventFactory))),
    () => commit('setEventsRequest', false)
  );
};

const actions = {
  initialize: ({ commit, state, rootState }, { search }) => {
    const user = rootState.authentication;

    const data = { query: { search } };

    commit('setPeople', []);
    commit('setReservations', []);
    commit('setEvents', []);
    commit('setReservationsRequest', true);
    commit('setEventsRequest', true);
    commit('setPeopleRequest', true);

    searchPeople({ user, data, commit, state });
  },
  quick: ({ commit, state, rootState }, { search }) => {
    const user = rootState.authentication;

    const data = { query: { search, limit: 10 } };
    commit('clearQuickSearchResults');
    commit('setQuickSearchResultsRequest', true);
    quickReservationSearch({ user, data, commit });
  },
};

const quickReservationSearch = ({ user, data, commit }) => {
  axios
    .post(user.resources.queries.reservations.url, data)
    .then((response) => commit('setQuickSearchResults', { type: 'reservations', items: response.data.results.map(reservationFactory) }))
    .catch((error) => console.log(error))
    .finally(() => quickEventSearch({ user, data, commit }));
};

const quickEventSearch = ({ user, data, commit }) => {
  axios
    .post(user.resources.queries.events.url, data)
    .then((response) => commit('setQuickSearchResults', { type: 'events', items: response.data.results.map(eventFactory) }))
    .catch((error) => console.log(error))
    .finally(() => quickPersonSearch({ user, data, commit }));
};

const quickPersonSearch = ({ user, data, commit }) => {
  axios
    .post(user.resources.queries.people.url, data)
    .then((response) => commit('setQuickSearchResults', { type: 'people', items: response.data.results.map(personFactory) }))
    .catch((error) => console.log(error))
    .finally(() => commit('setQuickSearchResultsRequest', false));
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
