<template>
  <v-container fluid class="pa-0">
    <arriving-guests></arriving-guests>
    <guest-nights></guest-nights>
  </v-container>
</template>

<script>
  import ArrivingGuests from './ArrivingGuests';
  import GuestNights from './GuestNights';

  export default {
    components: {
      ArrivingGuests,
      GuestNights,
    },
  };
</script>
