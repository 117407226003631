import Vue from 'vue';
import rollbar from '../util/rollbar';

if (import.meta.env.ROLLBAR_CLIENT_SIDE_TOKEN) {
  Vue.prototype.$rollbar = rollbar;

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };

  window.onerror = function (msg, url, line, col, error) {
    rollbar.error(error, { msg, url, line, col });
  };
}

export default rollbar;
