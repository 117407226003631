<template>
  <div
    class="error--text v-messages">
    <div v-for="(value, key) in errors" :key="key">
      {{ $t(`contact.${key}`) }}: {{ value.join(', ')}}
    </div>
  </div>
</template>

<script>
  export default {
    props: ['errors']
  };
</script>
