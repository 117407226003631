import { paginatedFetch } from '../../util/paginatedFetch';
import { reservationFactory } from '../../util/reservation';

const state = {
  reservations: [],
};

const getters = {};

const mutations = {
  setReservations: (state, payload) => {
    state.reservations = payload;
  },
};

const actions = {
  initialize: ({ commit, rootGetters, rootState, state }, { year, month, id }) => {
    commit('setReservations', []);
    const user = rootState.authentication;

    const from = new Date(year, parseInt(month) - 1);
    const to = new Date(year, month);
    const data = { query: { room_id: id, from_date: from, to_date: to, statuses: rootState.data.liveReservationStatuses } };
    paginatedFetch(
      user.resources.queries.reservations.url,
      data,
      (results) => commit('setReservations', state.reservations.concat(results.map(reservationFactory)))
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
