import Vue from 'vue';
import VueI18n from 'vue-i18n';
import I18n from 'i18n-js';

// eslint-disable-next-line no-undef
I18n.locale = i18nData.locale;
// eslint-disable-next-line no-undef
I18n.translations = { [i18nData.locale]: i18nData.translations };

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    day: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    },
    month: {
      year: 'numeric', month: 'long'
    },
    hoursAndMinutes: {
      hour: 'numeric', minute: 'numeric'
    },
  },
  hu: {
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    },
    day: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'long'
    },
    month: {
      year: 'numeric', month: 'long'
    },
    hoursAndMinutes: {
      hour: 'numeric', minute: 'numeric'
    },
  }
};

const i18n = new VueI18n({
  locale: I18n.locale,
  fallbackLocale: 'en',
  dateTimeFormats,
});

// eslint-disable-next-line no-undef
i18n.setLocaleMessage(I18n.locale, i18nData.translations);
export default i18n;
