<template>
  <v-expansion-panel>
    <v-expansion-panel-header v-slot="{ open }">
      <v-container fluid>
        <v-row justify="center">
          <v-col class="pl-0 align-center d-flex">
            <v-icon>mdi-currency-usd</v-icon>
            <span v-t="'reservation.spendings'"></span>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-if="filteredSpendings.length !== 0"
              readonly
              text
              dense
              :label="$t('spending.to_be_paid')"
              :value="payableSum"
              suffix="Ft"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-if="filteredSpendings.length !== 0"
              readonly
              text
              dense
              :value="sum"
              :label="$t('spending.total')"
              suffix="Ft"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="open"
            cols="2"
          >
            <v-btn
              @click.stop="addNew"
              v-t="'spending.add'"
            ></v-btn>
          </v-col>
        </v-row>
      </v-container>

    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        :headers="headers"
        :items="filteredSpendings"
        sort-by="title"
        disable-sort
        hide-default-footer
        dense
        class="spendings-table"
      >
        <template v-slot:item.title="{ item, index }">
          <v-text-field
            v-model="item.title"
            dense
            :hide-details="!errorsFor(index, '.*')"
            :error-messages="errorsFor(index, 'title')"
            flat
            filled
          ></v-text-field>
        </template>
        <template v-slot:item.amount="{ item, index }">
          <v-text-field
            v-model="item.amount"
            type="number"
            dense
            :hide-details="!errorsFor(index, '.*')"
            :error-messages="errorsFor(index, 'amount')"
            flat
            filled
            suffix="Ft"
          ></v-text-field>
        </template>
        <template v-slot:item.occursAt="{ item, index }">
          <datetime-picker
            :disabled="readonly"
            v-model="item.occursAt"
            flat
            :hide-details="!errorsFor(index, '.*')"
            :error-messages="errorsFor(index, 'occurs_at')"
          ></datetime-picker>
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn @click="remove(item)" icon>
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.paid="{ item }">
          <v-switch v-model="item.paid"></v-switch>
        </template>
        <template v-slot:item.kind="{ item, index }">
          <translated-select
            v-model="item.kind"
            filled
            prefix="spending.kinds"
            :items="$store.state.data.spendTypes"
            :hide-details="!errorsFor(index, '.*')"
            :error-messages="errorsFor(index, 'kind')"
          ></translated-select>
        </template>
        <template v-slot:item.category="{ item, index }">
          <translated-select
            v-model="item.category"
            filled
            prefix="spending.categories"
            :items="$store.state.data.spendCategories"
            :hide-details="!errorsFor(index, '.*')"
            :error-messages="errorsFor(index, 'category')"
          ></translated-select>
        </template>
        <template v-slot:no-data>
          <span v-t="'spending.empty'"></span>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import TranslatedSelect from './TranslatedSelect';
  import DatetimePicker from './pickers/DatetimePicker';

  export default {
    components: {
      TranslatedSelect,
      DatetimePicker,
    },
    props: {
      value: {
        type: Array,
      },
      label: {
        type: String,
        default: '',
      },
      existing: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: ''
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errors: {
        type: [Object, Array]
      }
    },
    data() {
      return {
        spendings: this.value,
        headers: [
          { text: this.$t('spending.occurs_at'), value: 'occursAt' },
          { text: this.$t('spending.title'), value: 'title' },
          { text: this.$t('spending.amount'), value: 'amount' },
          { text: this.$t('spending.kind'), value: 'kind' },
          { text: this.$t('spending.category'), value: 'category' },
          { text: this.$t('spending.paid'), value: 'paid' },
          { text: this.$t('spending.remove'), value: 'id' }
        ],
      };
    },
    computed: {
      filteredSpendings: ({ spendings }) => spendings?.filter(item => item._destroy !== 1) || [],
      sum: ({ filteredSpendings }) => filteredSpendings.reduce((x, item) => x + parseInt(item.amount), 0),
      payableSum: ({ filteredSpendings }) => filteredSpendings.filter(item => !item.paid).reduce((x, item) => x + parseInt(item.amount), 0),
    },
    methods: {
      addNew() {
        this.spendings.push({
          title: '',
          amount: 0,
          occursAt: new Date(),
          paid: false,
          kind: 'normal',
          category: 'other'
        });
      },
      errorsFor(index, type) {
        return Object.keys(this.errors)
          .filter(key => key.match(new RegExp(`^spendings\\[${index}\\]\\.${type}$`)))
          .map(key => this.errors[key])
          .join(' ');
      },
      remove(item) {
        this.$confirm(this.$t('spending.confirm_delete'))
          .then(response => {
            if (response) {
              const index = this.spendings.indexOf(item);

              if (index >= 0) {
                this.spendings.splice(index, 1);
              }
              this.spendings.push({ _destroy: 1, id: item.id });
            }
          });
      },
    },
    watch: {
      value() {
        this.spendings = this.value;
      },
    },
  };
</script>

<style lang="scss">
.spendings-table {
  width: 100%;

  td {
    border-bottom: none !important;
    &:first-child {
      padding-left: 0 !important;
    }
  }
}
</style>
