<template>
  <dialog-container
    v-model="open"
    :close="reallyClose"
    :item="user"
    :title="email"
    :dirty="dirty"
    type="user"
    ref="dialog"
    klass="lime"
  >
    <template v-slot:body>
      <v-container fluid>
        <v-text-field
          :error-messages="errors.email"
          :label="$t('settings.users.email')"
          :readonly="currentUser.role === 'regular'"
          dense
          filled
          prepend-icon="mdi-email"
          v-model="email"
        ></v-text-field>

        <v-select
          :error-messages="errors.role"
          :items="currentUser.role === 'regular' ? [role] : ['admin', 'regular']"
          :label="$t('settings.users.role')"
          :readonly="currentUser.role === 'regular'"
          filled
          prepend-icon="mdi-account-circle"
          v-model="role"
        >
          <template v-slot:selection="{ item }">
            {{ $t(`settings.users.roles.${item}`) }}
          </template>
          <template v-slot:item="{ item }">
            {{ $t(`settings.users.roles.${item}`) }}
          </template>
        </v-select>

        <v-text-field
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :error-messages="errors.password"
          :label="$t('settings.account.password')"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          autocomplete="new-password"
          filled
          prepend-icon="mdi-form-textbox-password"
          v-model="password"
        ></v-text-field>

        <v-text-field
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :error-messages="errors.passwordConfirmation"
          :label="$t('settings.account.password_confirmation')"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          autocomplete="new-password"
          filled
          prepend-icon="mdi-form-textbox-password"
          v-model="passwordConfirmation"
        ></v-text-field>
      </v-container>
    </template>

    <template v-slot:actions>
      <v-spacer></v-spacer>

      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-t="'settings.users.save'"
        :disabled="!dirty"
      ></v-btn>

      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="user?.url && (user.id !== currentUser.id)"
        v-t="'settings.users.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'settings.users.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../../shared/DialogContainer';

  export default {
    components: {
      DialogContainer,
    },
    data() {
      return {
        open: false,
        show: false,
        email: '',
        password: '',
        passwordConfirmation: '',
        role: null,
        currentUser: this.$store.state.authentication,
      };
    },
    computed: {
      dirty() {
        const user = this.user || {};

        return !(
          this.email === user.email &&
          this.role === user.role &&
          !this.password && !this.passwordConfirmation
        );
      },
      user() {
        return this.$store.state.settings.selectedUser;
      },
      errors() {
        return this.$store.state.settings.userErrors;
      },
    },
    methods: {
      save() {
        if (this.passwordCheck()) {
          this.$store.dispatch('settings/saveUser', {
            url: this.user.url,
            data: {
              email: this.email,
              role: this.role,
              password: this.password,
              passwordConfirmation: this.passwordConfirmation,
            },
          });
        }
      },
      remove() {
        this.$confirm(`${this.$t('settings.users.confirm_delete')}<br/>${this.email}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('settings/deleteUser', this.user);
            }
          });
      },
      reallyClose() {
        this.$store.commit('settings/setUserErrors', {});
        this.open = false;
      },
      passwordCheck() {
        if (this.password !== this.passwordConfirmation) {
          this.$store.commit('settings/setUserErrors', {
            password: [this.$t('activerecord.errors.models.user.attributes.password.mismatch')],
            passwordConfirmation: [this.$t('activerecord.errors.models.user.attributes.password.mismatch')]
          });

          return false;
        }
        return true;
      },
    },
    watch: {
      user() {
        if (!this.user) {
          this.open = false;
          return;
        }

        this.open = true;
        this.email = this.user.email;
        this.role = this.user.role || 'regular';
        this.password = '';
        this.passwordConfirmation = '';
      },
      open() {
        if (!this.open) {
          this.password = '';
          this.passwordConfirmation = '';
          this.show = false;
        }
      },
    }
  };
</script>
