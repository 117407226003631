<template>
  <v-menu
    bottom
    offset-y
    origin="top left"
    right
    v-model="open"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
            v-on="{ ...onMenu, ...onTooltip }"
            color="primary"
            dark
            elevation="3"
            fab
            height="20"
            width="20"
          >
            {{ events.length }}
          </v-btn>
        </template>
        <div>
          <div v-for="event in events" :key="event.id">
            <span>
              {{ $d(event.startsAt,  'hoursAndMinutes') }}
            </span>
            <span>
              {{ event.title.slice(0, 30) }}
              {{ event.title.length > 30 ? '...' : '' }}
            </span>
          </div>
        </div>
      </v-tooltip>
    </template>

    <v-card
      class="mx-auto"
      max-width="300"
      tile
      width="300"
    >
      <v-list class="pa-0">
        <event-list-item
          :item="event"
          :key="event.id"
          v-for="event in events"
        ></event-list-item>
        <v-list-item @click="addEvent" two-line>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2" v-t="'event.new'"></v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
  import EventListItem from '../shared/EventListItem';

  export default {
    components: {
      EventListItem,
    },
    props: ['events', 'date'],
    data() {
      return {
        open: false,
      };
    },
    methods: {
      addEvent() {
        this.$store.dispatch('calendar/newEvent', { startsAt: this.date });
      },
    }
  };
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 12px;
}
</style>
