<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" align="center">
      <v-col>
        <span class="text-h6" v-t="'statistics.guest_nights'"></span>
      </v-col>
      <v-col>
        <year-selector v-model="year"></year-selector>
      </v-col>
      <v-col>
        <month-selector v-model="month"></month-selector>
      </v-col>
      <v-col>
        <v-btn
          @click.stop="generate"
        >{{ $t('statistics.generate')}}</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="guestNights?.length === 0">
      <v-col>
        <span> {{ $vuetify.lang.t('$vuetify.noDataText') }}</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-for="roomType in roomTypes" :key="roomType">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t(`settings.rooms.room_types.${roomType}.title`) }}
          </v-card-title>
          <v-card-text>
            <guests-data-table
              :countries="$store.getters['statistics/guestNightsCountries']"
              :items="$store.getters['statistics/guestNightsFor'](roomType)"
            ></guests-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import dayjs from 'dayjs';
  import GuestsDataTable from './GuestsDataTable';
  import MonthSelector from './MonthSelector';
  import YearSelector from './YearSelector';

  export default {
    components: {
      GuestsDataTable,
      MonthSelector,
      YearSelector,
    },
    data() {
      return {
        year: dayjs().year(),
        month: null,
      };
    },
    computed: {
      guestNights() {
        return this.$store.state.statistics.guestNights;
      },
      roomTypes() {
        return this.$store.getters['statistics/guestNightsRoomTypes'];
      },
    },
    methods: {
      generate() {
        this.$store.dispatch('statistics/fetchGuestNights', {
          year: this.year,
          month: this.month,
        });
      },
    },
    mounted() {
      this.$store.commit('statistics/setGuestNights', null);
    }
  };
</script>
