/* eslint-disable camelcase */

export function guestDataFor(person) {
  const { id, ...personData } = person;
  let tourist_tax = 'KOTELES';
  if (person.birthdate) {
    const today = new Date();
    const date = new Date(person.birthdate);

    if ((today.getFullYear() - date.getFullYear()) < 18) {
      tourist_tax = 'im1';
    }
  }
  return { person_id: id, tourist_tax, ...personData };
}
