<template>
  <dialog-container
    :close="reallyClose"
    v-model="open"
    :item="item"
    :title="title"
    :dirty="dirty"
    type="inventory"
    ref="dialog"
    klass="blue"
  >
    <template v-slot:body>
      <v-container fluid class="px-0">
        <v-row no-gutters>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
              :error-messages="itemErrors.title"
              :label="$t('inventory.title')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="title"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
              :error-messages="itemErrors.partno"
              :label="$t('inventory.partno')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="partno"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
              :error-messages="itemErrors.price"
              :label="$t('inventory.price')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="price"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-text-field
              :error-messages="itemErrors.vendor_price"
              :label="$t('inventory.vendor_price')"
              :readonly="readonly"
              filled
              prepend-icon="mdi-subtitles"
              v-model="vendorPrice"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :label="$t('inventory.quantity')"
              :readonly="true"
              filled
              prepend-icon="mdi-counter"
              v-model="quantityDisplayed"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :label="$t('inventory.incoming')"
              :readonly="quantityOutgoing > 0"
              :disabled="quantityOutgoing > 0"
              type="number"
              filled
              clearable
              prepend-icon="mdi-counter"
              v-model="quantityIncoming"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :label="$t('inventory.outgoing')"
              :readonly="quantityIncoming > 0"
              :disabled="quantityIncoming > 0"
              type="number"
              filled
              clearable
              prepend-icon="mdi-counter"
              v-model="quantityOutgoing"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'inventory.save'"
        :disabled="itemRequest || !dirty"
      ></v-btn>
      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="item?.url && !readonly"
        v-t="'inventory.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'inventory.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import DialogContainer from '../shared/DialogContainer';

  export default {
    components: {
      DialogContainer,
    },
    data() {
      return {
        open: false,
        note: '',
        title: '',
        partno: '',
        price: '',
        vendorPrice: '',
        quantity: 0,
        quantityIncoming: null,
        quantityOutgoing: null,
      };
    },
    computed: {
      quantityDisplayed() {
        const op = this.quantityOutgoing > 0 ? '-' : '+';
        return `${this.quantity} ${op} ${Math.abs(this.quantityChange)} = ${this.quantityResult}`;
      },
      quantityResult() {
        return parseInt(this.quantity) + this.quantityChange;
      },
      quantityChange() {
        return parseInt((this.quantityOutgoing > 0 ? this.quantityOutgoing * -1 : this.quantityIncoming) || 0);
      },
      dirty() {
        const item = this.item || {};
        return !(this.title === item.title &&
          this.partno === item.partno &&
          this.price === item.price &&
          this.vendorPrice === item.vendor_price &&
          this.quantityIncoming === null &&
          this.quantityOutgoing === null
        );
      },
      item() {
        return this.$store.state.inventory.item;
      },
      itemErrors() {
        return this.$store.state.inventory.itemErrors;
      },
      itemRequest() {
        return this.$store.state.inventory.itemRequest;
      },
      readonly() {
        if (!this.item) {
          return;
        }
        return this.item.url && (this.item.auth || []).indexOf('update') === -1;
      },
    },
    watch: {
      item() {
        if (!this.item) {
          this.open = false;
          return;
        }
        this.open = true;
        this.title = this.item.title;
        this.partno = this.item.partno;
        this.price = this.item.price;
        this.vendorPrice = this.item.vendor_price;
        this.quantity = this.item.quantity;
      }
    },
    methods: {
      reallyClose() {
        this.$store.commit('inventory/setItemErrors', []);
        this.open = false;
      },
      save() {
        this.$store.dispatch('inventory/saveItem', {
          url: this.item.url,
          data: {
            title: this.title,
            partno: this.partno,
            price: this.price,
            vendor_price: this.vendorPrice,
            quantity_change: this.quantityChange,
          },
        }).then(() => {
          this.quantityIncoming = null;
          this.quantityOutgoing = null;
        });
      },
      remove() {
        this.$confirm(`${this.$t('inventory.confirm_delete')}<br/>${this.title}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('inventory/deleteItem', {
                url: this.item.url,
              });
            }
          });
      },
    },
  };
</script>
