<template>
  <dialog-container
    :close="reallyClose"
    :item="reservation"
    :title="titleFromGuests || titleFromNote"
    :dirty="dirty"
    type="reservation"
    :klass="reservationStyle({ status, paid })"
    ref="dialog"
    v-model="open"
  >
    <template v-slot:body>
      <v-expansion-panels
        multiple
        flat
        v-model="panels"
      >
        <v-expansion-panel>
          <v-expansion-panel-content class="px-0">
            <v-container fluid class="px-0">
              <v-row no-gutters>
                <v-col cols="12" lg="3" md="6" sm="12">
                  <datetime-picker
                    :disabled="readonly"
                    :label="$t('reservation.starts_at')"
                    :error-messages="reservationErrors.starts_at"
                    prepend-icon="mdi-calendar"
                    v-model="startsAt"
                  ></datetime-picker>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="12">
                  <datetime-picker
                    :disabled="readonly"
                    :label="$t('reservation.ends_at')"
                    :error-messages="reservationErrors.ends_at"
                    prepend-icon="mdi-calendar"
                    v-model="endsAt"
                  ></datetime-picker>
                </v-col>
                <v-col cols="12" lg="1" md="2" sm="12">
                  <v-text-field
                    :label="$t('reservation.nights')"
                    :value="nights"
                    filled
                    prepend-icon="mdi-weather-night"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="2" md="4" sm="12">
                  <v-select
                    :error-messages="reservationErrors.room_id"
                    :items="rooms"
                    :label="$t('reservation.room')"
                    :readonly="readonly"
                    filled
                    v-model="roomId"
                  >
                    <template v-slot:prepend>
                      <v-icon>mdi-bed</v-icon>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="12">
                  <translated-select
                    prepend-icon="mdi-list-status"
                    :label="$t('reservation.status')"
                    :readonly="readonly"
                    filled
                    prefix="reservation.statuses"
                    :error-messages="reservationErrors.status"
                    :items="$store.state.data.reservationStatuses"
                    v-model="status"
                  >
                  </translated-select>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="6"
                  sm="12"
                  v-if="room.mandatory_guests"
                >
                  <translated-select
                    prepend-icon="mdi-swap-horizontal-bold"
                    :label="$t('reservation.sales_channel')"
                    :readonly="readonly"
                    filled
                    prefix="reservation.sales_channels"
                    postfix="title"
                    :has-description="true"
                    :error-messages="reservationErrors.sales_channel"
                    :items="$store.state.data.salesChannels"
                    v-model="salesChannel"
                  >
                  </translated-select>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  md="6"
                  sm="12"
                  v-if="room.mandatory_guests"
                >
                  <translated-select
                    prepend-icon="mdi-swap-horizontal-bold"
                    :label="$t('reservation.market_segment')"
                    :readonly="readonly"
                    filled
                    prefix="reservation.market_segments"
                    postfix="title"
                    :has-description="true"
                    :error-messages="reservationErrors.market_segment"
                    :items="$store.state.data.marketSegments"
                    v-model="marketSegment"
                  >
                  </translated-select>
                </v-col>
                <v-col cols="12" lg="3" md="6" sm="12">
                  <v-text-field
                    prepend-icon="mdi-currency-usd"
                    :label="$t('reservation.gross_amount')"
                    :readonly="readonly"
                    type="number"
                    filled
                    :error-messages="reservationErrors.gross_amount"
                    v-model="grossAmount"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" lg="6" md="12" sm="12">
                  <v-textarea
                    :error-messages="reservationErrors.note"
                    :label="$t('reservation.note')"
                    :readonly="readonly"
                    auto-grow
                    filled
                    prepend-icon="mdi-text"
                    v-model="note"
                    style="min-height: 11rem"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" lg="6" md="12" sm="12">
                  <guests-autocomplete
                    :label="$t('reservation.guests')"
                    :existing="!!reservation?.url"
                    type="Reservation"
                    :readonly="readonly"
                    v-model="guests"
                    :errors="reservationErrors"
                  ></guests-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <spendings
          :label="$t('reservation.spendings')"
          :existing="!!reservation?.url"
          type="Reservation"
          :readonly="readonly"
          v-model="spendings"
          :errors="reservationErrors"
        ></spendings>
        <reservation-history
          v-if="reservation?.url"
          :reservation="reservation"
          :active="panels.indexOf(2) !== -1"
        ></reservation-history>
      </v-expansion-panels>
    </template>
    <template v-slot:actions>
      <v-btn
        @click="jump"
        text
        v-if="jumpable"
        v-t="'reservation.jump'"
      ></v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="save"
        color="green darken-1"
        text
        v-if="!readonly"
        v-t="'reservation.save'"
        :disabled="reservationRequest || !dirty"
      ></v-btn>
      <v-btn
        @click="remove"
        color="red darken-1"
        text
        v-if="reservation?.url && !readonly"
        v-t="'reservation.delete'"
      ></v-btn>
      <v-btn
        @click="$refs.dialog && $refs.dialog.dialogClose()"
        color="yellow darken-1"
        text
        v-t="'reservation.close'"
      ></v-btn>
    </template>
  </dialog-container>
</template>

<script>
  import { router } from '../../routes/router';
  import GuestsAutocomplete from '../shared/GuestsAutocomplete';
  import Spendings from '../shared/Spendings';
  import reservationMixin from '../../mixins/reservations';
  import TranslatedSelect from '../shared/TranslatedSelect';
  import DialogContainer from '../shared/DialogContainer';
  import DatetimePicker from '../shared/pickers/DatetimePicker';
  import ReservationHistory from './ReservationHistory';
  import { compareGuests, compareDates, compareArrays } from '../../util/comparators';
  import { spendingsAttributesFor } from '../../util/spending';

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  export default {
    mixins: [reservationMixin],
    components: {
      DialogContainer,
      GuestsAutocomplete,
      TranslatedSelect,
      Spendings,
      DatetimePicker,
      ReservationHistory,
    },
    data() {
      return {
        panels: [0],
        open: false,
        startsAt: new Date(),
        endsAt: new Date(),
        note: '',
        status: 'pending',
        salesChannel: 'direct_online',
        marketSegment: 'leisure_individual',
        grossAmount: 0,
        roomId: 0,
        nights: 0,
        guests: [],
        spendings: [],
        paid: false,
      };
    },
    computed: {
      dirty() {
        const reservation = this.reservation || {};

        return !(compareDates(this.startsAt, reservation.startsAt) &&
          compareDates(this.endsAt, reservation.endsAt) &&
          this.note === reservation.note &&
          this.status === reservation.status &&
          +this.grossAmount === +reservation.gross_amount &&
          this.salesChannel === reservation.sales_channel &&
          this.marketSegment === reservation.market_segment &&
          this.roomId === reservation.room_id &&
          compareArrays(this.spendings, reservation.spendings) &&
          compareGuests(this.guests, reservation.guests)
        );
      },
      reservation() {
        return this.$store.state.calendar.selectedReservation;
      },
      reservationErrors() {
        return this.$store.state.calendar.reservationErrors;
      },
      reservationRequest() {
        return this.$store.state.calendar.reservationRequest;
      },
      rooms() {
        return this.$store.getters['authentication/getRoomsWithHeaders'];
      },
      room() {
        return this.$store.getters['authentication/getRoomById'](this.roomId) || {};
      },
      titleFromGuests() {
        return this.guests?.map(g => g.name).sort().join(', ');
      },
      titleFromNote() {
        return this.note?.split('\n')[0];
      },
      readonly() {
        if (!this.reservation) {
          return;
        }
        return this.reservation.url && this.reservation?.auth?.indexOf('update') === -1;
      },
      jumpable() {
        const { year, month, id } = router.currentRoute.params;
        return this.reservation?.id &&
          parseInt(id) !== this.room?.calendar?.id &&
          year !== this.startsAt.getFullYear().toString() &&
          month !== (this.startsAt.getMonth() + 1).toString();
      },
    },
    methods: {
      reallyClose() {
        this.$store.commit('calendar/setReservationErrors', []);
        this.open = false;
      },
      save() {
        this.$store.dispatch('calendar/saveReservation', {
          url: this.reservation.url,
          data: {
            starts_at: this.startsAt,
            ends_at: this.endsAt,
            note: this.note,
            status: this.status,
            sales_channel: this.salesChannel,
            market_segment: this.marketSegment,
            gross_amount: this.grossAmount,
            room_id: this.roomId,
            guests_attributes: this.guests,
            spendings_attributes: spendingsAttributesFor(this.spendings || [])
          },
        });
      },
      remove() {
        this.$confirm(`${this.$t('reservation.confirm_delete')}<br/>${this.titleFromGuests || this.titleFromNote}`)
          .then(response => {
            if (response) {
              this.$store.dispatch('calendar/deleteReservation', {
                url: this.reservation.url,
              }).then(() => {
                this.open = false;
              });
            }
          });
      },
      calculateNights() {
        this.nights = Math.round(Math.abs((this.endsAt - this.startsAt) / oneDay));
      },
      jump() {
        const { id } = this.room.calendar;
        const year = this.startsAt.getFullYear().toString();
        const month = (this.startsAt.getMonth() + 1).toString();
        router.push({ name: 'calendar', params: { id, year, month } }).catch(() => {});
        this.$refs.dialog.dialogClose();
      },
    },
    watch: {
      reservation() {
        if (!this.reservation) {
          this.open = false;
          return;
        }
        const reservation = this.reservation || {};

        this.panels = [0];
        this.open = !!reservation;
        this.startsAt = reservation.startsAt;
        this.endsAt = reservation.endsAt;
        this.note = reservation.note;
        this.status = reservation.status;
        this.grossAmount = reservation.gross_amount;
        this.salesChannel = reservation.sales_channel;
        this.marketSegment = reservation.market_segment;
        this.roomId = reservation.room_id;
        this.guests = reservation.guests?.map(guest => ({ ...guest }));
        this.spendings = reservation.spendings?.map(s => ({ ...s }));
        this.paid = reservation.paid;
        this.calculateNights();
      },
      startsAt() {
        this.calculateNights();
      },
      endsAt() {
        this.calculateNights();
      },
    }
  };
</script>

<style lang="scss">
.reservation-dialog {
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-expansion-panel-header {
    padding: 0;
  }
}
</style>
