<template>
  <v-card
    height="100%"
    tile
  >
    <v-card-title>
      {{ $t(`dashboard.reservations_${arrivals ? 'starting' : 'finishing'}`) }}
      ({{ $d(date, 'short') }})
    </v-card-title>
    <v-list
      dense
      v-if="filteredReservations && filteredReservations.length"
    >
      <reservation-list-item
        :item="reservation"
        :arrivals="arrivals"
        :key="reservation.id"
        v-for="reservation in filteredReservations"
      ></reservation-list-item>
    </v-list>
    <v-card-subtitle v-else v-t="`dashboard.no_${arrivals ? 'starting' : 'finishing'}_reservations`"></v-card-subtitle>
    <v-progress-linear
      indeterminate
      absolute
      bottom
      :active="reservations === null"
      :reverse="!arrivals"
    ></v-progress-linear>
  </v-card>
</template>

<script>
  import ReservationListItem from '../shared/ReservationListItem';

  export default {
    components: {
      ReservationListItem,
    },
    props: {
      reservations: {
        type: Array
      },
      date: {
        type: Date
      },
      arrivals: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      filteredReservations() {
        const statuses = {
          true: ['checked_in', 'canceled'],
          false: ['checked_out', 'canceled']
        }[this.arrivals];
        const date = {
          true: 'startsAt',
          false: 'endsAt'
        }[this.arrivals];
        return this.reservations
          ?.filter(r => r[date].isSameDay(this.date) && (statuses.indexOf(r.status) === -1))
          .sort((r, o) => this.arrivals ? r.startsAt - o.startsAt : r.endsAt - o.endsAt);
      }
    },
  };
</script>
