import axios from 'axios';
import { router } from '../../routes/router';
import { paginatedFetch } from '../../util/paginatedFetch';
import { guestDataFor } from '../../util/guest';
import { reservationFactory } from '../../util/reservation';
import { eventFactory } from '../../util/event';

const state = {
  reservations: [],
  reservationsRequest: false,
  selectedReservation: null,
  reservationErrors: {},
  events: [],
  eventsRequest: false,
  selectedEvent: null,
  eventErrors: {},
  eventRequest: false,
  reservationRequest: false,
  reservationHistory: [],
  reservationHistoryRequest: false,
};

const getters = {};

const mutations = {
  setReservations: (state, payload) => {
    state.reservations = payload;
  },
  setReservationErrors: (state, payload) => {
    state.reservationErrors = payload;
  },
  selectReservation: (state, payload = {}) => {
    state.selectedReservation = { timestamp: new Date(), ...payload };
  },
  setEvents: (state, payload) => {
    state.events = payload;
  },
  selectEvent: (state, payload) => {
    state.selectedEvent = { timestamp: new Date(), ...payload };
  },
  setEventErrors: (state, payload) => {
    state.eventErrors = payload;
  },
  addContactToEvent: (state, payload) => {
    if (state.selectedEvent) {
      state.selectedEvent.guests.push(guestDataFor(payload));
    }
  },
  addContactToReservation: (state, payload) => {
    if (state.selectedReservation) {
      state.selectedReservation.guests.push(guestDataFor(payload));
    }
  },
  setEventRequest: (state, payload) => {
    state.eventRequest = payload;
  },
  setEventsRequest: (state, payload) => {
    state.eventsRequest = payload;
  },
  setReservationRequest: (state, payload) => {
    state.reservationRequest = payload;
  },
  setReservationsRequest: (state, payload) => {
    state.reservationsRequest = payload;
  },
  setReservationHistory: (state, payload) => {
    state.reservationHistory = payload;
  },
  setReservationHistoryRequest: (state, payload) => {
    state.reservationHistoryRequest = payload;
  },
};

const actions = {
  initialize: ({ commit, rootGetters, rootState, state }, { year, month, id }) => {
    commit('setReservations', []);
    commit('setReservationsRequest', true);
    commit('setEventsRequest', true);
    commit('setEvents', []);
    const user = rootState.authentication;

    const from = new Date(year, parseInt(month) - 1);
    const to = new Date(year, month);
    const roomIds = rootGetters['authentication/getRoomsFor'](parseInt(id)).map(room => room.id);
    const data = { query: { room_id: roomIds, from_date: from, to_date: to, statuses: rootState.data.liveReservationStatuses } };
    paginatedFetch(
      user.resources.queries.reservations.url,
      data,
      (results) => commit('setReservations', state.reservations.concat(results.map(reservationFactory))),
      () => commit('setReservationsRequest', false)
    );
    paginatedFetch(
      user.resources.queries.events.url,
      data,
      (results) => commit('setEvents', state.events.concat(results.map(eventFactory))),
      () => commit('setEventsRequest', false)
    );
  },
  newReservation: ({ dispatch }, { startsAt, endsAt, roomId }) => {
    return dispatch('selectReservation', { guests: [], spendings: [], gross_amount: 0, status: 'pending', roomId, startsAt, endsAt });
  },
  selectReservation: ({ commit, rootGetters, rootState }, payload) => {
    const { company } = rootState.authentication;

    commit('selectReservation', null);
    if (payload.url) {
      commit('setReservationRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectReservation', reservationFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setReservationRequest', false));
    } else {
      let date = new Date();
      let roomId = rootGetters['authentication/getAllRooms'][0].id;
      if (router.currentRoute.name === 'calendar') {
        const { year, month, id } = router.currentRoute.params;
        date = Date.firstOf(year, month);
        roomId = rootGetters['authentication/getCalendarById'](parseInt(id)).rooms[0].id;
      }
      const startsAt = payload.startsAt || date;
      const endsAt = startsAt.addDays(1);
      const reservationStart = company.config.reservation.start.split(':');
      const reservationEnd = company.config.reservation.end.split(':');
      startsAt.setHours(parseInt(reservationStart[0]));
      startsAt.setMinutes(parseInt(reservationStart[1]));
      endsAt.setHours(parseInt(reservationEnd[0]));
      endsAt.setMinutes(parseInt(reservationEnd[1]));

      const reservation = {
        starts_at: startsAt.toISOString(),
        ends_at: endsAt.toISOString(),
        room_id: payload.roomId || roomId,
      };
      setTimeout(() => commit('selectReservation', { ...reservation, ...payload, startsAt, endsAt }), 1);
    }
  },
  saveReservation: ({ commit, dispatch, rootGetters }, payload) => {
    const room = rootGetters['authentication/getRoomById'](payload.data.room_id);

    const url = payload.url ? payload.url : room.resources.reservations.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setReservationRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setReservationErrors', []);
        reInitCalendar({ dispatch });
        commit('addMessage', { message: 'reservation.save_success', type: 'success' }, { root: true });
        setTimeout(() => commit('selectReservation', reservationFactory(response.data)), 1000);
      })
      .catch(response => commit('setReservationErrors', response.response.data.errors))
      .finally(() => commit('setReservationRequest', false));
  },
  deleteReservation: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectReservation', null);
        reInitCalendar({ dispatch });
        commit('addMessage', { message: 'reservation.delete_success', type: 'success' }, { root: true });
      });
  },
  newEvent: ({ dispatch }, { startsAt }) => {
    return dispatch('selectEvent', { guests: [], title: '', startsAt });
  },
  selectEvent: ({ commit, rootState }, payload) => {
    const { company } = rootState.authentication;
    commit('selectEvent', null);
    if (payload.url) {
      commit('setEventRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectEvent', eventFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setEventRequest', false));
    } else {
      const { year, month } = router.currentRoute.params;
      const startsAt = payload?.startsAt ?? (year ? new Date(parseInt(year), parseInt(month) - 1, 1) : new Date());

      const eventStart = company.config.event.start.split(':');
      startsAt.setHours(parseInt(eventStart[0]));
      startsAt.setMinutes(parseInt(eventStart[1]));
      setTimeout(() => commit('selectEvent', eventFactory({ ...payload, starts_at: startsAt.toISOString() })), 1);
    }
  },
  saveEvent: ({ commit, dispatch, rootState }, payload) => {
    const user = rootState.authentication;
    const url = payload.url ? payload.url : user.company.resources.events.url;
    const method = payload.url ? 'patch' : 'post';
    commit('setEventRequest', true);
    return axios[method](url, payload.data)
      .then(response => {
        commit('setEventErrors', []);
        reInitCalendar({ dispatch });
        commit('addMessage', { message: 'event.save_success', type: 'success' }, { root: true });
        setTimeout(() => commit('selectEvent', eventFactory(response.data)), 1000);
      })
      .catch(response => commit('setEventErrors', response.response.data.errors))
      .finally(() => commit('setEventRequest', false));
  },
  deleteEvent: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectEvent', null);
        reInitCalendar({ dispatch });
        commit('addMessage', { message: 'event.delete_success', type: 'success' }, { root: true });
      });
  },
  loadReservationHistory: ({ commit, dispatch }, payload) => {
    commit('setReservationHistory', []);
    commit('setReservationHistoryRequest', true);
    return axios
      .get(payload.url)
      .then(response => commit('setReservationHistory', response.data.results))
      .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
      .finally(() => commit('setReservationHistoryRequest', false));
  },
};

function reInitCalendar({ dispatch }) {
  if (router.currentRoute.name === 'calendar') {
    const { year, month, id } = router.currentRoute.params;
    dispatch('initialize', { year, month, id });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
