import Login from '../components/welcome/Login';
import Calendar from '../components/calendar/Calendar';
import VueRouter from 'vue-router';
import Contacts from '../components/contacts/Contacts';
import Dashboard from '../components/dashboard/Dashboard';
import SettingsMain from '../components/settings/SettingsMain';
import SearchMain from '../components/search/SearchMain';
import Room from '../components/room/Room';
import StatisticsMain from '../components/statistics/StatisticsMain';
import SettingsNTAKMain from '../components/settings/ntak/SettingsNTAKMain';
import InventoryMain from '../components/inventory/InventoryMain.vue';

const routes = [
  { path: '/dashboard', component: Dashboard, exact: true, name: 'dashboard' },
  { path: '/login', component: Login, name: 'login' },
  { path: '/rooms/:id/:year/:month', component: Room, name: 'room' },
  { path: '/calendars/:id/:year/:month', component: Calendar, name: 'calendar' },
  { path: '/contacts', component: Contacts, exact: true, name: 'contacts' },
  { path: '/settings', component: SettingsMain, exact: true, name: 'settings' },
  { path: '/settings/ntak', component: SettingsNTAKMain, name: 'ntak' },
  { path: '/search', component: SearchMain, name: 'search' },
  { path: '/statistics', component: StatisticsMain, name: 'statistics' },
  { path: '/inventory', component: InventoryMain, name: 'inventory' },
  { path: '/', component: Dashboard },
  { path: '*', redirect: '/dashboard' }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export { router };
