<template>
  <v-data-table
    disable-sort
    :hide-default-footer="certificates.length < 11"
    :headers="headers"
    :items="certificates"
  >
    <template v-slot:item.created_at="{ item }">
      {{ dayjs(item.created_at).format('YYYY-MM-DD HH:mm') }}
    </template>
    <template v-slot:item.updated_at="{ item }">
      {{ dayjs(item.updated_at).format('YYYY-MM-DD HH:mm') }}
    </template>
    <template v-slot:item.not_before="{ item }">
      {{ item.not_before ? dayjs(item.not_before).format('YYYY-MM-DD HH:mm') : '-' }}
    </template>
    <template v-slot:item.not_after="{ item }">
      {{ item.not_after ? dayjs(item.not_after).format('YYYY-MM-DD HH:mm') : '-' }}
    </template>
    <template v-slot:item.type="{ item }">
      {{ certificateType(item)}}
    </template>
    <template v-slot:item.status="{ item }">
      {{ $t(`settings.certificates.statuses.${item.status}`)}}
    </template>
    <template v-slot:item.download="{ item }">
      <v-btn
        @click="download(item)"
        icon
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.upload="{ item }">
      <v-file-input
        accept=".cer"
        class="pa-1 ma-0"
        v-if="item.status === 'pending'"
        v-model="file"
        @change="upload(item)"
        :error="item.errors?.length > 0"
        flat
        rounded
        hide-input
        :prepend-icon="item.type === 'auth' ? 'mdi-certificate' : 'mdi-certificate-outline'"
      >
      </v-file-input>
    </template>
  </v-data-table>
</template>

<script>
  import dayjs from 'dayjs';
  export default {
    props: {
      certificates: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        file: null,
        headers: [
          {
            text: this.$t('settings.certificates.created_at'),
            value: 'created_at',
          },
          {
            text: this.$t('settings.certificates.updated_at'),
            value: 'updated_at',
          },
          {
            text: this.$t('settings.certificates.type'),
            value: 'type',
          },
          {
            text: this.$t('settings.certificates.status'),
            value: 'status',
          },
          {
            text: this.$t('settings.certificates.not_before'),
            value: 'not_before',
          },
          {
            text: this.$t('settings.certificates.not_after'),
            value: 'not_after',
          },
          {
            text: this.$t('settings.certificates.download'),
            value: 'download',
          },
          {
            text: this.$t('settings.certificates.upload'),
            value: 'upload',
          }
        ]
      };
    },
    methods: {
      dayjs,
      certificateType(item) {
        return this.$t(`settings.certificates.types.${item.type}`);
      },
      download(item) {
        this.$store.dispatch('certificates/download', item);
      },
      upload(item) {
        this.$store.dispatch('certificates/upload', { item, file: this.file });
      },
    },

  };
</script>
