<template>
  <v-list-item
    class="blue lighten-5"
    dense
  >
    <v-row>
      <v-col cols="9" lg="10" md="10" sm="9">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs}">
            <v-btn
              @click.stop.prevent="click"
              icon
              small
              v-bind="attrs"
              v-on="on">
              <v-icon color="grey lighten-1">mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
          <span v-t="'settings.rooms.edit'"></span>
        </v-tooltip>
        <v-icon small>mdi-{{room.icon}}</v-icon>
        <span>{{room.title}}</span>
      </v-col>
      <v-col class="text-right" cols="2" lg="2" md="2" sm="2">
        <v-btn :disabled="first" @click.stop.prevent="up" icon>
          <v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
        <v-btn :disabled="last" @click.stop.prevent="down" icon>
          <v-icon>mdi-arrow-down-bold</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>

<script>
  export default {
    props: ['value', 'calendar'],
    data() {
      return {
        room: this.value,
      };
    },
    computed: {
      readonly() {
        if (!this.room) {
          return;
        }
        return this.room.url && (this.room.auth || []).indexOf('update') === -1;
      },
      index() {
        return this.calendar.rooms.indexOf(this.room);
      },
      first() {
        return this.index < 1;
      },
      last() {
        if (this.index === -1) {
          return true;
        }
        return this.index === this.calendar.rooms.length - 1;
      },
    },
    watch: {
      value() {
        this.room = this.value;
      },
      room() {
        this.$emit('input', this.room);
      }
    },
    methods: {
      click() {
        this.$store.commit('settings/selectRoom', { timestamp: new Date(), ...this.room, calendar: this.calendar });
      },
      up() {
        if (this.first) {
          return;
        }

        const otherRoom = this.calendar.rooms[this.index - 1];
        // eslint-disable-next-line vue/no-mutating-props
        this.calendar.rooms.splice(this.index - 1, 2, this.room, otherRoom);
      },
      down() {
        if (this.last) {
          return;
        }

        const otherRoom = this.calendar.rooms[this.index + 1];
        // eslint-disable-next-line vue/no-mutating-props
        this.calendar.rooms.splice(this.index, 2, otherRoom, this.room);
      },
      remove() {
        this.$confirm(`${this.$t('settings.rooms.confirm_delete')}<br/>${this.room.title}`)
          .then(response => {
            if (response) {
              // eslint-disable-next-line vue/no-mutating-props
              this.calendar.rooms.splice(this.index, 1);
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-list-item {
    padding-right: 80px;
  }

  .row {
    > div {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
</style>
