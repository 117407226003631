<template>
  <v-select
    :label="$t('statistics.month')"
    :items="months"
    v-model="month"
  ></v-select>
</template>

<script>
  import dayjs from 'dayjs';

  export default {
    props: {
      value: {
        type: Number,
      },
    },
    computed: {
      month: {
        get() { return this.value; },
        set(value) { this.$emit('input', value); }
      },
      months() {
        dayjs.locale(this.$store.state.authentication.company.locale);

        const months = [...Array(12).keys()]
          .map(i => ({ value: i + 1, text: dayjs().month(i).format('MMMM') }));

        months.unshift({ value: null, text: this.$t('statistics.all') });
        return months;
      },
    }

  };
</script>
