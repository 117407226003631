import axios from 'axios';

const state = {
  companyErrors: {},
  selectedCalendar: null,
  selectedRoom: null,
  selectedUser: null,
  calendarErrors: {},
  roomErrors: {},
  userErrors: {},
};

const getters = {};

const mutations = {
  setCompanyErrors: (state, payload) => {
    state.companyErrors = payload;
  },
  selectCalendar: (state, payload) => {
    state.selectedCalendar = payload;
  },
  selectRoom: (state, payload) => {
    state.selectedRoom = payload;
  },
  selectUser: (state, payload) => {
    state.selectedUser = payload;
  },
  setCalendarErrors: (state, payload) => {
    state.calendarErrors = payload;
  },
  setRoomErrors: (state, payload) => {
    state.roomErrors = payload;
  },
  setUserErrors: (state, payload) => {
    state.userErrors = payload;
  },
};

const actions = {
  saveCompany: ({ commit, rootState }, payload) => {
    const company = rootState.authentication.company;
    const { locale, ...newCompany } = payload;
    const newLocale = company.locale !== locale.value;
    return axios
      .patch(company.url, payload)
      .then(() => {
        commit('setCompanyErrors', {});
        commit('addMessage', { message: 'settings.company.save_success', type: 'success' }, { root: true });
        commit('authentication/updateCompany', newCompany, { root: true });

        if (newLocale) {
          setTimeout(() => document.location.reload(), 1000);
        }
      })
      .catch(response => commit('setCompanyErrors', response.response.data.errors));
  },
  saveUser: ({ commit, rootState }, { url, data }) => {
    const { company } = rootState.authentication;
    const postData = {
      user: {
        email: data.email,
        role: data.role,
      }
    };
    const { password, passwordConfirmation } = data;
    if (password && passwordConfirmation) {
      postData.user.password = password;
      postData.user.password_confirmation = passwordConfirmation;
    }
    const method = url ? 'patch' : 'post';
    axios[method](url || company.resources.users.url, postData)
      .then(response => {
        commit('addMessage', { message: 'settings.account.save_success', type: 'success' }, { root: true });
        commit('selectUser', response.data);
        commit('setUserErrors', {});
        commit('authentication/updateUser', response.data, { root: true });
      })
      .catch(response => commit('setUserErrors', response.response.data.errors));
  },
  deleteUser: ({ commit }, { url, id }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectUser', null);
        commit('addMessage', { message: 'settings.users.delete_success', type: 'success' }, { root: true });
        commit('authentication/removeUser', { id }, { root: true });
      })
      .catch(response => commit('setUserErrors', { email: response.response.data.errors[0].email }));
  },
  deleteCalendar: ({ commit }, { url, id }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectCalendar', null);
        commit('addMessage', { message: 'settings.calendars.delete_success', type: 'success' }, { root: true });
        commit('authentication/removeCalendar', { id }, { root: true });
      })
      .catch(response => commit('setCalendarErrors', { title: response.response.data.errors[0].title }));
  },
  saveCalendar: ({ commit, rootState }, { url, data }) => {
    const { company } = rootState.authentication;
    const method = url ? 'patch' : 'post';
    return axios[method](url || company.resources.calendars.url, data)
      .then(response => {
        commit('setCalendarErrors', {});
        commit('selectCalendar', response.data);
        commit('addMessage', { message: 'settings.calendars.save_success', type: 'success' }, { root: true });
        commit('authentication/updateCalendarTitle', response.data, { root: true });
      })
      .catch(response => commit('setCalendarErrors', response.response.data.errors));
  },
  saveCalendars: ({ commit, rootState }, payload) => {
    const { company } = rootState.authentication;
    const data = {
      company: {
        calendars_attributes: payload.map((calendar, ci) => ({
          position: ci + 1,
          id: calendar.id,
          rooms_attributes: calendar.rooms?.map((room, ri) => ({
            position: ri + 1,
            id: room.id
          }))
        }))
      }
    };
    return axios.patch(company.url, data)
      .then(() => {
        commit('addMessage', {
          message: 'settings.calendars.multiple_save_success',
          type: 'success'
        }, { root: true });
      })
      .catch(response => commit('addMessage', {
        message: 'messages.something_went_wrong',
        type: 'warning'
      }, { root: true }));
  },
  deleteRoom: ({ commit }, { id, url, calendar }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectRoom', null);
        commit('addMessage', { message: 'settings.rooms.delete_success', type: 'success' }, { root: true });
        commit('authentication/removeRoom', { id, calendar }, { root: true });
      })
      .catch(response => commit('setRoomErrors', { title: response.response.data.errors[0].title }));
  },
  saveRoom: ({ commit }, { url, calendar, data }) => {
    const method = url ? 'patch' : 'post';
    return axios[method](url || calendar.resources.rooms.url, data)
      .then(response => {
        commit('setRoomErrors', {});
        commit('addMessage', { message: 'settings.rooms.save_success', type: 'success' }, { root: true });
        commit('authentication/updateRoom', { ...response.data, calendar }, { root: true });
        commit('selectRoom', { ...response.data, calendar });
      })
      .catch(response => commit('setRoomErrors', response.response.data.errors));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
