<template>
  <v-autocomplete
    :items="$store.state.countries"
    :label="label"
    filled
    item-text="name"
    item-value="id"
    v-model="inputValue"
    :error-messages="errors"
  >
    <template v-slot:prepend>
      <v-icon
        @click="addDefault"
        color="blue"
      >mdi-flag</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
  export default {
    props: ['value', 'errors', 'label'],

    computed: {
      inputValue: {
        get() { return this.value; },
        set(newValue) { this.$emit('input', newValue); }
      }
    },
    methods: {
      addDefault() {
        this.inputValue = this.$store.state.authentication.company.country_code;
      },
    },
  };
</script>
