<template>
  <div>
    <settings-room-dialog></settings-room-dialog>
    <settings-calendar-dialog></settings-calendar-dialog>
    <v-expansion-panels popout>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <v-icon>mdi-account</v-icon>
            <span v-t="'settings.account.header'"></span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <settings-accounts></settings-accounts>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="company.auth.indexOf('update') !== -1"
      >
        <v-expansion-panel-header>
          <div>
            <v-icon>mdi-domain</v-icon>
            <span v-t="'settings.company.header'"></span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <settings-company></settings-company>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="company.auth.indexOf('update') !== -1"
      >
        <v-expansion-panel-header @click="calendarHeader = !calendarHeader">
          <div>
            <v-icon>mdi-calendar</v-icon>
            <span v-t="'settings.calendars.header'"></span>
            <v-switch
              @click.stop.prevent="editing = !editing"
              hide-details
              class="float-end ma-0 d-inline-flex"
              :label="editing ? $t('settings.calendars.multiple_edit') : $t('settings.calendars.multiple_rearrange')"
              v-if="calendarHeader"
            ></v-switch>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <settings-calendars :editing="editing"></settings-calendars>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import SettingsCompany from './company/SettingsCompany';
  import SettingsCalendars from './calendars/SettingsCalendars';
  import SettingsAccounts from './accounts/SettingsAccounts';
  import SettingsRoomDialog from './calendars/SettingsRoomDialog';
  import SettingsCalendarDialog from './calendars/SettingsCalendarDialog';

  export default {
    components: {
      SettingsCompany,
      SettingsAccounts,
      SettingsCalendars,
      SettingsRoomDialog,
      SettingsCalendarDialog,
    },
    data() {
      return {
        editing: false,
        calendarHeader: false,
      };
    },
    computed: {
      company() {
        return this.$store.state.authentication.company;
      },
    },
    mounted() {
      this.$store.dispatch('accommodations/initialize');
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
</style>
