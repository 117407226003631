<template>
  <v-speed-dial
    bottom
    direction="top"
    fixed
    right
    transition="slide-y-reverse-transition"
    v-model="fab"
  >
    <template v-slot:activator>
      <v-btn
        color="blue darken-2"
        dark
        fab
        v-model="fab"
      >
        <v-icon v-if="fab">mdi-plus</v-icon>
        <v-icon v-else>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="newReservation"
          color="blue"
          dark
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-calendar</v-icon>
        </v-btn>
      </template>
      <span v-t="'calendar.new_reservation'"></span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="newPerson"
          color="blue"
          dark
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <span v-t="'calendar.new_person'"></span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="newEvent"
          color="blue"
          dark
          fab
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>
      </template>
      <span v-t="'calendar.new_event'"></span>
    </v-tooltip>
  </v-speed-dial>
</template>

<script>
  export default {
    data: () => ({
      fab: false,
    }),
    methods: {
      newReservation() {
        this.$store.dispatch('calendar/newReservation', { roomId: undefined, startsAt: new Date(), endsAt: new Date() });
      },
      newPerson() {
        this.$store.dispatch('contacts/selectPerson', {});
      },
      newEvent() {
        this.$store.dispatch('calendar/newEvent', {});
      }
    },
  };
</script>
