import axios from 'axios';
import { paginatedFetch } from '../../util/paginatedFetch';
import { personFactory } from '../../util/person';

const state = {
  people: [],
  selectedPerson: null,
  personErrors: {},
  personRequest: false,
  peopleRequest: false,
  enabled: true,
};

const getters = {};

const mutations = {
  setPeople: (state, payload) => {
    state.people = payload;
  },
  setPersonRequest: (state, payload) => {
    state.personRequest = payload;
  },
  setPeopleRequest: (state, payload) => {
    state.peopleRequest = payload;
  },
  selectPerson: (state, payload) => {
    state.selectedPerson = payload;
  },
  setPersonErrors: (state, payload) => {
    state.personErrors = payload;
  },
  enable: (state, payload) => {
    state.enabled = true;
  },
  disable: (state, payload) => {
    state.enabled = false;
  }
};

const actions = {
  initialize: ({ commit, rootState, state }) => {
    commit('setPeople', []);
    commit('setPeopleRequest', true);
    const user = rootState.authentication;
    paginatedFetch(
      user.resources.queries.people.url,
      { query: { search: '' } },
      (results) => commit('setPeople', state.people.concat(results.map(personFactory))),
      () => commit('setPeopleRequest', false)
    );
  },
  selectPerson: ({ commit }, payload) => {
    commit('selectPerson', null);
    if (payload.url) {
      commit('setPersonRequest', true);
      axios
        .get(payload.url)
        .then(response => commit('selectPerson', personFactory(response.data)))
        .catch(() => commit('addMessage', { message: 'messages.something_went_wrong', type: 'warning' }, { root: true }))
        .finally(() => commit('setPersonRequest', false));
    } else {
      commit('selectPerson', payload);
    }
  },
  savePerson: ({ commit, dispatch, rootState }, { url, data, addToReservation, addToEvent }) => {
    const user = rootState.authentication;
    const method = url ? 'patch' : 'post';
    commit('disable');

    commit('setPersonRequest', true);
    return axios[method](url || user.company.resources.people.url, data)
      .then(response => {
        commit('setPersonErrors', []);
        dispatch('initialize');
        commit('addMessage', { message: 'contact.save_success', type: 'success' }, { root: true });
        if (addToEvent) {
          commit('calendar/addContactToEvent', response.data, { root: true });
        }
        if (addToReservation) {
          commit('calendar/addContactToReservation', response.data, { root: true });
        }
        commit('selectPerson', personFactory(response.data));
      })
      .catch(response => commit('setPersonErrors', response.response.data.errors))
      .finally(() => {
        commit('setPersonRequest', false);
        return commit('enable');
      });
  },
  deletePerson: ({ commit, dispatch }, { url }) => {
    return axios.delete(url)
      .then(() => {
        commit('selectPerson', null);
        dispatch('initialize');
        commit('addMessage', { message: 'contact.delete_success', type: 'success' }, { root: true });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
