<template>
  <v-navigation-drawer
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    v-model="open"
  >
    <v-list dense>
      <v-list-item
        v-if="!$vuetify.breakpoint.lgAndUp"
        @click="open = false"
      >
        <v-list-item-action>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="item in items">
        <v-list-group
          :key="item.text"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
          v-if="item.children"
          v-model="item.model"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-t="item.text">
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :key="i"
            :to="child.path"
            exact
            v-for="(child, i) in item.children"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          :key="item.text"
          :to="item.path"
          v-else
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-t="item.text">
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
  import { router } from '../../routes/router';

  const today = new Date();

  export default {
    props: ['value'],
    data() {
      return {
        items: [],
      };
    },
    computed: {
      company() {
        return this.$store.state.authentication.company;
      },
      role() {
        return this.$store.state.authentication.role;
      },
      open: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      menuItems() {
        const isCalendar = router.currentRoute.name === 'calendar';
        const year = isCalendar ? router.currentRoute.params.year : today.getFullYear();
        const month = isCalendar ? router.currentRoute.params.month : (today.getMonth() + 1);

        const calendars = this.company?.calendars.map((calendar) => ({
          icon: 'mdi-calendar-month',
          text: calendar.title,
          path: { name: 'calendar', params: { id: calendar.id, year, month } }
        })) || [];
        const settings = [
          {
            icon: 'mdi-cog',
            text: this.$t('menu.general_settings'),
            path: { name: 'settings' },
          }
        ];
        if (this.company?.ntak_enabled) {
          settings.push(
            {
              icon: 'mdi-chess-rook',
              text: this.$t('menu.ntak'),
              path: { name: 'ntak' },
            }
          );
        }
        this.items = [
          { icon: 'mdi-home', text: 'menu.dashboard', path: { name: 'dashboard' } },
          {
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            text: 'menu.calendars',
            model: true,
            children: calendars
          },
          { icon: 'mdi-contacts', text: 'menu.contacts', path: { name: 'contacts' } },
          { icon: 'mdi-chart-box', text: 'menu.statistics', path: { name: 'statistics' } }
        ];

        if (this.role === 'admin') {
          this.items.push({
            icon: 'mdi-clipboard-list',
            text: 'menu.inventory',
            path: { name: 'inventory' }
          });
          this.items.push({
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            text: 'menu.settings',
            children: settings
          });
        }
      }
    },
    watch: {
      value() {
        this.open = this.value;
      },
      open() {
        this.$emit('input', this.open);
      },
      company() {
        this.menuItems();
      },
      '$route'(to) {
        this.menuItems();
      },
    },
    mounted() {
      this.menuItems();
    }
  };
</script>
