import axios from 'axios';

const state = {
  liveReservationStatuses: [],
  locales: [],
  marketSegments: [],
  realRoomTypes: [],
  reservationStatuses: [],
  roomStatuses: [],
  roomTypes: [],
  salesChannels: [],
  sexes: [],
  spendTypes: [],
  spendCategories: [],
  touristTaxes: [],
};

const mutations = {
  /* eslint-disable camelcase */
  setData: (state, { live_reservation_statuses, locales, market_segments, real_room_types, reservation_statuses, room_statuses, room_types, sales_channels, sexes, spend_types, spend_categories, tourist_taxes }) => {
    state.liveReservationStatuses = live_reservation_statuses;
    state.locales = locales;
    state.marketSegments = market_segments;
    state.realRoomTypes = real_room_types;
    state.reservationStatuses = reservation_statuses;
    state.roomStatuses = room_statuses;
    state.roomTypes = room_types;
    state.salesChannels = sales_channels;
    state.sexes = sexes;
    state.spendTypes = spend_types;
    state.spendCategories = spend_categories;
    state.touristTaxes = tourist_taxes;
  },
  /* eslint-enable camelcase */
};
const getters = {};

const actions = {
  initialize: ({ commit }) => {
    return axios
      .get('/api/datas')
      .then(({ data }) => commit('setData', data));
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
