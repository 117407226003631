<template>
  <v-card
    tile
  >
    <v-card-title v-t="'dashboard.events'"></v-card-title>
    <v-list
      dense
      v-if="events && events.length"
    >
      <event-list-item
        :item="event"
        :key="event.id"
        v-for="event in events"
      ></event-list-item>
    </v-list>
    <v-card-subtitle v-else v-t="'dashboard.no_events'"></v-card-subtitle>
    <v-progress-linear
      indeterminate
      absolute
      bottom
      :active="events === null"
    ></v-progress-linear>
  </v-card>
</template>

<script>
  import EventListItem from '../shared/EventListItem';

  export default {
    components: {
      EventListItem
    },
    props: {
      events: {
        type: Array
      }
    }
  };
</script>
