<template>
  <v-card>
    <v-card-title>
      <div v-t="'settings.rooms_without_accommodation.header'"></div>
    </v-card-title>
    <v-card-text>
      <settings-room-dialog></settings-room-dialog>
      <v-data-table
        :items="$store.state.rooms.roomsWithoutAccommodation"
        :headers="headers"
      >
        <template v-slot:item.calendar_id="{ item }">
          {{ $store.getters['authentication/getCalendarById'](item.calendar_id).title }}
        </template>
        <template v-slot:item.title="{ item }">
          <v-icon>mdi-{{ item.icon }}</v-icon>
          {{ item.title }}
        </template>
        <template v-slot:item.room_type="{ item }">
          {{ $t(`settings.rooms.room_types.${item.room_type}.title`)}}
        </template>
        <template v-slot:item.status="{ item }">
          {{ $t(`settings.rooms.statuses.${item.status}`)}}
        </template>
        <template v-slot:item.id="{ item }">
          <v-btn
            icon
            @click="selectRoom(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import SettingsRoomDialog from '../calendars/SettingsRoomDialog';

  export default {
    components: {
      SettingsRoomDialog,
    },
    data() {
      return {
        headers: [
          {
            text: this.$t('settings.rooms.calendar'),
            value: 'calendar_id'
          },
          {
            text: this.$t('settings.rooms.title'),
            value: 'title'
          },
          {
            text: this.$t('settings.rooms.room_type'),
            value: 'room_type'
          },
          {
            text: this.$t('settings.rooms.status'),
            value: 'status'
          },
          {
            text: '',
            value: 'id'
          }
        ],
      };
    },
    methods: {
      selectRoom(item) {
        const calendar = this.$store.getters['authentication/getCalendarById'](item.calendar_id);
        this.$store.commit('settings/selectRoom', { ...item, calendar });
      },
    },
    mounted() {
      this.$store.dispatch('rooms/initialize');
    }
  };
</script>
