<template>
  <v-autocomplete
    dark
    :label="$t('search.label')"
    flat
    dense
    :items="items"
    :filter="(item) => item"
    hide-details
    prepend-inner-icon="mdi-magnify"
    solo-inverted
    hide-no-data
    :search-input.sync="value"
    @keydown.enter="search"
    clearable
    @click:clear="clear"
  >
    <template v-slot:append>&nbsp;</template>
    <template v-slot:item="{ item }">
      <person-list-item
        :item="item"
        @click="click(item)"
        v-if="item.itemType === 'person'"
      ></person-list-item>
      <reservation-list-item
        :item="item"
        date-format="long"
        @click="clear"
        v-if="item.itemType === 'reservation'"
      ></reservation-list-item>
      <event-list-item
        :item="item"
        date-format="long"
        @click="clear"
        v-if="item.itemType === 'event'"
      ></event-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
  import { router } from '../../routes/router';
  import PersonListItem from '../shared/PersonListItem';
  import ReservationListItem from '../shared/ReservationListItem';
  import EventListItem from '../shared/EventListItem';

  let timer;

  export default {
    components: {
      PersonListItem,
      ReservationListItem,
      EventListItem,
    },
    data() {
      return {
        value: '',
      };
    },
    computed: {
      items() {
        const { people, reservations, events } = this.$store.state.search.quickSearchResults;
        switch (router.currentRoute.name) {
          case 'calendar':
          case 'room':
            return [...reservations, ...events, ...people];
          default:
            return [...people, ...reservations, ...events];
        }
      }
    },
    methods: {
      click(item) {
        this.$store.commit('search/clearQuickSearchResults');
        this.$store.dispatch('contacts/selectPerson', { url: item.url });
      },
      search() {
        if (!this.value || this.value?.length < 3) {
          return;
        }
        router.push({ name: 'search', query: { query: this.value, from: router.currentRoute.name } }).catch(() => {});
      },
      clear() {
        this.value = '';
        this.$store.commit('search/clearQuickSearchResults');
      },
    },
    watch: {
      value(val) {
        clearTimeout(timer);
        if (!val || val?.length < 3) {
          return;
        }
        timer = setTimeout(() => {
          this.$store.dispatch('search/quick', { search: val });
        }, 100);
      },
    },
    mounted() {
      this.value = this.$route.query.query;
    }
  };
</script>
