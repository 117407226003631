<template>
  <v-progress-linear
    indeterminate
    top
    absolute
    :active="request"
  ></v-progress-linear>
</template>

<script>
  export default {
    computed: {
      request() {
        const { reservationsRequest, eventsRequest, eventRequest, reservationRequest, reservationHistoryRequest } = this.$store.state.calendar;
        const { arrivingGuestsRequest } = this.$store.state.statistics;
        const { personRequest, peopleRequest } = this.$store.state.contacts;
        return reservationsRequest || eventsRequest || eventRequest || reservationRequest || personRequest || peopleRequest || reservationHistoryRequest || arrivingGuestsRequest;
      },
    }
  };
</script>
