<template>
  <v-container fluid class="pa-0">
    <v-row justify="center" align="center">
      <v-col>
        <span class="text-h6" v-t="'statistics.arriving_guests'"></span>
      </v-col>
      <v-col>
        <year-selector v-model="year"></year-selector>
      </v-col>
      <v-col>
        <month-selector v-model="month"></month-selector>
      </v-col>
      <v-col>
        <v-btn
          @click.stop="generate"
        >{{ $t('statistics.generate')}}</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="arrivingGuests !== null">
      <v-col>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('statistics.total') }}
          </v-card-title>
          <v-card-text>
            <guests-data-table
              :countries="$store.getters['statistics/arrivingGuestsCountries']"
              :items="arrivingGuestsTableTotal"
            ></guests-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('statistics.tax_free') }}
          </v-card-title>
          <v-card-text>
            <guests-data-table
              :countries="$store.getters['statistics/arrivingGuestsCountries']"
              :items="arrivingGuestsTableTaxFree"
            ></guests-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import dayjs from 'dayjs';
  import GuestsDataTable from './GuestsDataTable';
  import MonthSelector from './MonthSelector';
  import YearSelector from './YearSelector';

  export default {
    components: {
      GuestsDataTable,
      MonthSelector,
      YearSelector,
    },
    data() {
      return {
        year: dayjs().year(),
        month: null,
      };
    },
    computed: {
      arrivingGuests() {
        return this.$store.state.statistics.arrivingGuests;
      },
      arrivingGuestsTableTotal() {
        return this.$store.getters['statistics/arrivingGuestsTable']('guests_count');
      },
      arrivingGuestsTableTaxFree() {
        return this.$store.getters['statistics/arrivingGuestsTable']('tax_free_guests_count');
      },
    },
    methods: {
      generate() {
        this.$store.dispatch('statistics/fetchArrivingGuests', {
          year: this.year,
          month: this.month,
        });
      },
    },
    mounted() {
      this.$store.commit('statistics/setArrivingGuests', null);
    }
  };
</script>
