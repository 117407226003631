<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    disable-sort
  >
    <template v-slot:item="{ item, headers }">
      <tr>
        <td
          v-for="header in headers"
          :key="header.value"
        >{{ item[header.value] || '' }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    props: {
      countries: {
        type: Array,
      },
      items: {
        type: Array,
      },
    },
    computed: {
      headers() {
        return this.countryHeaders(this.countries);
      },
    },
    methods: {
      countryHeaders(dataTable) {
        const headers = [
          { text: this.$t('statistics.year'), value: 'year' },
          { text: this.$t('statistics.month'), value: 'month' }
        ];

        headers.push({
          text: this.$store.state.countries.find(c => c.id === 'HU')?.name,
          value: 'HU'
        });
        dataTable.forEach(country => {
          if (country !== 'HU') {
            headers.push({
              text: this.$store.state.countries.find(c => c.id === country)?.name || this.$t('statistics.unknown'),
              value: country || 'unknown'
            });
          }
        });
        headers.push({ text: this.$t('statistics.total'), value: 'total' });
        return headers;
      }
    },
  };
</script>
